import React, { useState, useEffect } from 'react';
import { Card, Table, TableBody, MenuItem, FormControl, TableCell, TableContainer, TableHead, TableRow, Button, CardContent } from '@mui/material';
import { createAttributes, getAttributes, updateAttribute, deleteAttribute, getAllTestCases, getTestStepsList, addUseData } from "../redux/actions/testcase";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { connect } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import Divider from '@mui/material/Divider';
import '../styles/project.css';
import '../styles/custom.css';
import '../styles/common.css';
import '../styles/files.css';

const Attribute = ({ createAttributes, getAttributes, updateAttribute, deleteAttribute, data, attributeListData, getAllTestCases, allTestCasesList, getTestStepsList, addUseData, refreshStepList }) => {

    const [newRowXpath, setNewRowXpath] = useState('');
    const [newRowValue, setNewRowValue] = useState('');
    const [newRowSleepTime, setNewRowSleepTime] = useState('');
    const [newRowIsEncrypted, setNewRowIsEncrypted] = useState('');
    const [newRowIsUnique, setNewRowIsUnique] = useState('');
    const [newRowIsEnv, setnewRowIsEnv] = useState('');
    const [attributeList, setAttributeList] = useState([]);
    const [testCaseList, setTestcaseList] = useState([]);
    const [error, setError] = useState('');
    const [stepName, setStepName] = useState('');
    const [stepId, setStepId] = useState('');
    const [testStepList, setTestStepList] = useState([]);
    const [selectedTestcase, setSelectedTestcase] = useState();
    const [selectedTeststep, setSelectedTeststep] = useState();

    useEffect(() => {
        attributeListData = null;
        getAllTestCases(0, 0, null, null, JSON.stringify({}), null).then(() => {
            createDropDownList();
            setStoredData();
        });
        if (data.step_id) {
            setStepId(data.step_id);
            getAttributes(data.step_id);
            setStepName(data.step_name);
        }
    }, [data]
    )


    const Env_Values = [
        { value: 'Url', label: 'Url' },
        { value: 'Username', label: 'Username' },
        { value: 'Password', label: 'Password' },
    ];

    const setStoredData = () => {
        if (data) {
            let refTestObj = data.refcasename
                ? {
                    id: data.reftest_id,
                    value: data.refcasename,
                    label: data.refcasename,
                }
                : null;

            let refStep = data.refstepname
                ? {
                    id: data.reftest_id,
                    value: data.refstepname,
                    label: data.refstepname,
                }
                : null;

            setSelectedTestcase(refTestObj);
            setSelectedTeststep(refStep);
        }
    };


    const createDropDownList = () => {
        let allTestCases = [];
        allTestCasesList?.forEach((tc, index) => {
            let obj = {
                id: tc.case_id,
                value: tc.case_name,
                label: tc.case_name
            }
            allTestCases.push(obj);
        });
        setTestcaseList(allTestCases);
    }

    const handleTestcaseChange = (selectedTestcase) => {
        setTestStepList([]);
        setSelectedTestcase(selectedTestcase);
        getTestStepsList(selectedTestcase.id).then((res) => {
            let allTeststeps = [];
            res?.data?.forEach((tc, index) => {
                let obj = {
                    id: tc.step_id,
                    value: tc.step_name,
                    label: tc.step_name
                }
                allTeststeps.push(obj);
            });
            setTestStepList(allTeststeps);
        })
    }

    const handleTeststepChange = (selectedTeststep) => {
        setSelectedTeststep(selectedTeststep);
    }

    useEffect(() => {
        if (attributeListData && attributeListData.length > 0) {
            let initialRows = [];
            attributeListData?.map((attribute, i) => {
                const obj = {
                    attr_id: attribute.attr_id,
                    index: i + 1,
                    attr_xpath: attribute.attr_xpath,
                    attr_value: attribute.attr_value,
                    attr_is_encrypted: attribute.attr_is_encrypted,
                    is_unique: attribute.is_unique,
                    env: attribute.env,
                    attr_sleeptime: attribute.attr_sleeptime
                }
                initialRows.push(obj);
                //  setRows(initialRows);
                setAttributeList(initialRows);
            })
        }
    }, [attributeListData])


    console.log("attrLIst",attributeList)

    const handleAddRow = () => {
        const newRow = {
            id: attributeList.length + 1,
            xPath: newRowXpath,
            value: newRowValue,
            sleepTime: newRowSleepTime,
            isEncrypted: newRowIsEncrypted,
            isUnique: newRowIsUnique,
            isEnv: newRowIsEnv,
        };

        setAttributeList([...attributeList, newRow]);
        setNewRowXpath('');
        setNewRowValue('');
        setNewRowSleepTime('');
        setNewRowIsEncrypted(false);
        setNewRowIsUnique(false);
        setnewRowIsEnv('');
        setError('');
    };

    const deleteAttributeById = (id, index) => {
        console.log("value in attributes", id, index)
        if (!attributeList[index].attr_id) {
            const updatedAttributeList = [...attributeList];
            updatedAttributeList.splice(index, 1);
            setAttributeList(updatedAttributeList);
        }
        else {
            deleteAttribute(id)
                .then(() => {
                    // getAttributes(data.step_id);
                    const updatedAttributeList = [...attributeList];
                    updatedAttributeList.splice(index, 1); // Remove the item at the given index

                    setAttributeList(updatedAttributeList);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

    }

    const handleXpathChange = (event, index) => {
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].attr_xpath = event.target.value;
        setAttributeList(updatedAttributeList);
    }

    const setIsEncrypted = (event, index) => {
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].attr_is_encrypted = event;
        setAttributeList(updatedAttributeList);
    }
    const setIsUnique = (event, index) => {
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].is_unique = event;
        setAttributeList(updatedAttributeList);
    }

    const setIsEnv = (event, index) => {
        console.log("ISENV", event)
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].env = event;
        setAttributeList(updatedAttributeList);
    }

    const handleValueChange = (event, index) => {
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].attr_value = event.target.value;
        setAttributeList(updatedAttributeList);
    }

    const handleSleepTimeChange = (event, index) => {
        const updatedAttributeList = [...attributeList];
        updatedAttributeList[index].attr_sleeptime
            = event.target.value;
        setAttributeList(updatedAttributeList);
    }

    const onSubmit = (index) => {
        if (!attributeList[index]?.attr_id) {
            const obj = {
                attr_xpath: attributeList[index]?.attr_xpath,
                attr_value: attributeList[index]?.attr_value,
                attr_expectedvalue: '',
                step_id: data.step_id,
                attr_is_encrypted: attributeList[index]?.attr_is_encrypted,
                is_unique: attributeList[index]?.is_unique,
                env: attributeList[index]?.env?.value,
                attr_sleeptime: attributeList[index]?.attr_sleeptime
            };

            createAttributes(obj)
                .then((res) => {
                    attributeList[index] = res.data;
                    console.log("attributes", res.data)
                    setAttributeList([...attributeList]);
                    toast.success("Test attribute created successfully");
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
        if (attributeList[index]?.attr_id) {
            attributeList?.map(data => data.attr_id === attributeList[index]?.attr_id)
            const object = {
                id: attributeList[index].attr_id,
                attr_xpath: attributeList[index]?.attr_xpath,
                attr_value: attributeList[index]?.attr_value,
                attr_expectedvalue: '',
                attr_is_encrypted: attributeList[index]?.attr_is_encrypted,
                is_unique: attributeList[index]?.is_unique,
                env: attributeList[index]?.env?.value,
                step_id: data.step_id,
                attr_sleeptime: attributeList[index]?.attr_sleeptime
            };
            console.log("update",object)
            updateAttribute(object).then((res) => {
                toast.success("Test attribute updated successfully");
            })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const saveReference = () => {
        let obj = {
            id: data?.step_id,
            reftest_id: selectedTestcase.id,
            refstep_id: selectedTeststep.id
        }
        addUseData(obj).then(() => {
            if (refreshStepList) {
                refreshStepList();
            }
        })
    }
    

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 className='label-bold'>{stepName}</h3>
                <Button onClick={handleAddRow} startIcon={<AddIcon />} style={{ marginLeft: 'auto' }}>
                    Add Attribute
                </Button>
            </div>

            <div className='marginTop'>
                <Divider></Divider>
            </div>

            <div className='marginTop'>
                <Card>
                    <CardContent>
                        <h6 style={{ color: '#34B1AA' }} className='label-bold'>Select references for use store data</h6>
                        <div className='flex-container marginTop'>
                            <div className='flex-item'>
                                <div>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select testcase"
                                        placeholder="Select testcase"
                                        value={selectedTestcase}
                                        name="name"
                                        options={testCaseList}
                                        onChange={handleTestcaseChange}
                                        menuPortalTarget={document.body} // Render dropdown menu outside the Card
                                        styles={{
                                            menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999 // Set a higher z-index for the dropdown menu portal
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='flex-item'>
                                <div>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select teststep"
                                        placeholder="Select teststep"
                                        value={selectedTeststep}
                                        name="name"
                                        options={testStepList}
                                        onChange={handleTeststepChange}
                                        menuPortalTarget={document.body} // Render dropdown menu outside the Card
                                        styles={{
                                            menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999 // Set a higher z-index for the dropdown menu portal
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='flex-item'>
                                <Tooltip title="Save reference">
                                    <SaveIcon style={{ color: '#34B1AA' }} onClick={() => saveReference()} className="action-icon" />
                                </Tooltip>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className='marginTop'>
                <TableContainer component={Card} className='dashboard-table table td'>
                    <div className="scroll">
                        <Table>
                            <TableHead className='th'>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>XPath</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Encrypt</TableCell>
                                    <TableCell>IsEnv</TableCell>
                                    <TableCell>Unique</TableCell>
                                    <TableCell>Sleep Time</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {attributeList.length === 0}
                                {attributeList?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            <TextField onChange={(event) => handleXpathChange(event, index)}
                                                type="text"
                                                value={row.attr_xpath}
                                            /></TableCell>

                                        <TableCell>
                                            <TextField
                                                onChange={(event) => handleValueChange(event, index)}
                                                type={row.attr_is_encrypted ? "password" : "text"}
                                                value={row.attr_value}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Checkbox checked={row.attr_is_encrypted} onChange={(e) => setIsEncrypted(e.target.checked, index)}></Checkbox>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ width: '100px' }}>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={Env_Values}
                                                    name={row.env}
                                                    value={Env_Values.find(option => option.value === row.env)}
                                                    onChange={(event) => setIsEnv(event, index)}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </div>
                                        </TableCell>

                                        {/* <TableCell>
                                            
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    
                                                    options={Env_Values}
                                                    value={row.env}
                                                    onChange={(event) => setIsEnv(event, index)}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                        container: base => ({ ...base, width: '100%' }),
                                                    }}
                                                />
                                            
                                        </TableCell> */}
                                        <TableCell>
                                            <Checkbox checked={row.is_unique} onChange={(e) => setIsUnique(e.target.checked, index)}></Checkbox>
                                        </TableCell>
                                        <TableCell>


                                            <TableCell>
                                                <TextField onChange={(event) => handleSleepTimeChange(event, index)}
                                                    type="number"
                                                    size="small"
                                                    InputProps={{
                                                        style: {
                                                            width: "70px",
                                                            minWidth: "70px",
                                                            padding: "0",
                                                        },
                                                    }}
                                                    value={row.attr_sleeptime}
                                                /></TableCell>
                                        </TableCell>
                                        <TableCell>

                                            <Tooltip title="Save">
                                                <SaveIcon style={{ color: '#34B1AA' }} onClick={() => onSubmit(index)} className="action-icon" />
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteAttributeById(row.attr_id, index)} className="action-icon clickIconClassDelete" />
                                            </Tooltip>


                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </div>
                </TableContainer>
            </div>
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    attributeListData: state.auth.attributesList,
    allTestCasesList: state.auth.allTestCasesList.rows
});

const mapDispatchToProps = {
    createAttributes,
    getAttributes,
    updateAttribute,
    deleteAttribute,
    getAllTestCases,
    getTestStepsList,
    addUseData
};

export default connect(mapStateToProps, mapDispatchToProps)(Attribute);
