import { CREATE_JIRA_TOKEN,JIRA_TOKEN_LIST,DELETE_JIRA_TOKEN,UPDATE_JIRA } from "./types";
import { toast } from 'react-toastify';
import jiraTokenService from "../../services/jiratoken.service";

export const getJiraList = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await jiraTokenService.getjiraList(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
            dispatch({
                type: JIRA_TOKEN_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch clock task list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const createjiratoken = (object) => async (dispatch) => {
    try {
        let res = await jiraTokenService.createjiratoken(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_JIRA_TOKEN,
                payload: res.data
            });
            console.log("result",res)
            toast.success("Jira Token "+res.data.projectkey+" created successfully");
            return res
        } else {
            toast.error("Unable to create Jira Ticket " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deleteJira = (id) => async (dispatch) => {
    try {
        console.log("id",id)
        let res = await jiraTokenService.deletejiratoken(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_JIRA_TOKEN,
                payload: res.data
            });
            toast.success("Jira Token deleted successfully");
        } else {
            toast.error("Unable to delete Jira Token : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const updateJira = (object) => async (dispatch) => {
    try {
        let res = await jiraTokenService.updatejiratoken(object);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_JIRA,
                payload: res.data
            });
            toast.success("Jira Token updated successfully");
        } else {
            toast.error("Unable to update Jira Token : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


