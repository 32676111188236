import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class NotificationService {
    constructor() {
        this.headers = createHeaders();
    }

    async getNotificationList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/notification`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async updateNotification (){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/notification`;
            const response = await axios.post(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    deleteNotification = async (id) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/notification`;
            const response = await axios.put(url, {
                id: id
            }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

}


export default new NotificationService();