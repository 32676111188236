import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class EnviromentPersonaService {
    constructor() {
        this.headers = createHeaders();
     }

    createPersona = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/persona`;
            const response = await axios.post(url, obj , this.headers);
            console.log("Respoonse",response)
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getPersonaList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/persona`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    async updatePersona(obj){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/persona`;
            const response = await axios.put(url,obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    async deletePersona(id){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/persona/${id}`;
            const response = await axios.delete(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

   
}


export default new EnviromentPersonaService();