import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class SuiteJobService {
    constructor() {
        this.headers = createHeaders();
    }

    async getSuiteJobList(proj_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/userwisesuitejob/?proj_id=${proj_id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

   async getDetailedSuiteReport(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/getSuiteJobBySearch`;
        const response = await axios.post(url, obj , this.headers);
        return response;
    }
    catch (error) {
        throw error.response.data.error;
    }
  }
  async deleteSuiteJob(suite_id) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/suite_delete/${suite_id}`;
        const response = await axios.delete(url, this.headers);
        return response;
    }
    catch (error) {
        throw error.response.data.error;
    }
}

    async sendReport(formData) {
      
        for (const [key, value] of formData.entries()) {
            console.log("Service",`${key}: ${value}`); 
        }
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/SendReport`;
            const response = await axios.post(url, formData , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new SuiteJobService();