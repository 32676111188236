import { CREATE_ENVIROMENT,
    ENVIROMENT_LIST,
    DELETE_ENVIROMENT,
    UPDATE_ENVIROMENT, } from "./types";
import { toast } from 'react-toastify';
import EnviromentService from "../../services/enviroment.service";

export const getEnviromentList = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await EnviromentService.getEnviromentList(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
            dispatch({
                type: ENVIROMENT_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch Project Environment : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const createEnviroment = (object) => async (dispatch) => {
    try {
        let res = await EnviromentService.createEnviroment(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_ENVIROMENT,
                payload: res.data
            });
            console.log("result",res)
            toast.success("Project Environment "+res.data.name+" created successfully");
            return res
        } else {
            toast.error("Unable to create Project Environment " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deleteEnviroment = (id) => async (dispatch) => {
    try {
        console.log("id",id)
        let res = await EnviromentService.deleteEnviroment(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_ENVIROMENT,
                payload: res.data
            });
            toast.success("Project Environment deleted successfully");
        } else {
            toast.error("Unable to delete Project Environment : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const updateEnviroment = (object) => async (dispatch) => {
    try {
        let res = await EnviromentService.updateEnviroment(object);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ENVIROMENT,
                payload: res.data
            });
            toast.success("Project Environment updated successfully");
        } else {
            toast.error("Unable to update Project Environment : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


