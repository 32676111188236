import { LOGIN_SUCCESS, PROJECT_LIST, CREATE_PROJECT } from "./types";
import usersService from "../../services/users.service";
import authService from "../../services/auth.service";
import projectService from "../../services/project.service";
import { toast } from "react-toastify";

export const login = (username, password) => async (dispatch) => {
  try {
    let res = await authService.login(username, password);
    if (res.status === 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      toast.success("Logged in successfully");
    } else {
      toast.error("Unable to login please ask administrator" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const loginsso = (provider, authorizationCode) => async (dispatch) => {
  try {
    let res = await usersService.getuserbysso(provider, authorizationCode);
    if (res.status === 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      toast.success("Logged in successfully");
    } else {
      console.log("Error response", res?.response?.data?.message);
      if (res) {
        toast.error(res?.response?.data?.message);
      }

      // toast.error("Unable to login please ask administrator" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getProjects =
  (page, pageSize, sortColumn, sortDirection, filters) => async (dispatch) => {
    try {
      let res = await projectService.getProjectList(
        page,
        pageSize,
        sortColumn,
        sortDirection,
        filters
      );
      if (res.status === 200) {
        dispatch({
          type: PROJECT_LIST,
          payload: res.data,
        });
      } else {
        toast.error("Unable to get project list" + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };

export const createProjects = (projectName) => async (dispatch) => {
  try {
    let res = await projectService.addNewProject(projectName);
    if (res.status === 200) {
      dispatch({
        type: CREATE_PROJECT,
        payload: res.data,
      });
      toast.success("Project created successfully");
    } else {
      toast.error("Unable to create project: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateProjectDetails = (projectDetails) => async (dispatch) => {
  try {
    let res = await projectService.updateProjectName(projectDetails);
    if (res.status === 200) {
      dispatch({
        type: CREATE_PROJECT,
        payload: res.data,
      });
      toast.success("Project updated successfully");
    } else {
      toast.error("Unable to update project : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    let res = await projectService.deleteProject(id);
    if (res.status === 200) {
      dispatch({
        type: CREATE_PROJECT,
        payload: res.data,
      });
      toast.success("Project deleted successfully");
    } else {
      toast.error("Unable to delete project : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const resetPassword = (obj) => async (dispatch) => {
  try {
    let res = await authService.forgetPassword(obj);
    if (res.status === 200) {
      toast.success(
        "Link has been sent to your register email id please check your mail box"
      );
    } else {
      toast.error("Something wend wrong Please try again later");
    }
  } catch (error) {
    toast.error(error);
  }
};

export const resetToken = (obj, navigate) => async (dispatch) => {
  try {
    let res = await authService.resetToken(obj);
    if (res.status === 200) {
      navigate("/signIn");
      toast.success("Password has been reset successfully");
    } else {
      toast.error("Something went wrong please try again later");
    }
  } catch (error) {
    toast.error(error);
  }
};

// export const logout = () => (dispatch) => {
//   try {
//     dispatch(showLoading());
//     localStorage.clear();
//     localStorage.removeItem("user");

//     dispatch({
//       type: LOGOUT,
//     });

//     dispatch({
//       type: SET_MESSAGE,
//       payload: "You have successfully logged out.",
//     });

//     dispatch(hideLoading());
//   } catch (e) {
//     console.error("logout", e);
//   }
// };

// export const authUser = () => async (dispatch) => {
//   dispatch(showLoading());
//   dispatch({
//     type: AUTH_USER,
//   });
//   dispatch(hideLoading());
// };

// export const updateUserInfo = (data) => (dispatch) => {
//   dispatch({
//     type: SET_USER_DATA,
//     payload: { user: data },
//   });
// };
