import { CREATE_JIRA_TOKEN,JIRA_TOKEN_LIST,DELETE_JIRA_TOKEN,UPDATE_JIRA } from "../actions/types";
    
      const initialState = {
        JiraTokenList: null,
      };
      
      const jiraToken = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          case JIRA_TOKEN_LIST:
            return {
              ...state,
              JiraTokenList: payload,
              
          };          
            case CREATE_JIRA_TOKEN:
              return {
                ...state,
                isjiraAccountCreated: true,
            };
            case DELETE_JIRA_TOKEN:
              return {
                ...state,
                isjiraAccountDeleted: true,
            };
            case UPDATE_JIRA:
              return {
                ...state,
                isjiraUpdated: true,
            };

          default:
            return state;
        }
      };
      export default jiraToken;
      