import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class EnviromentService {
    constructor() {
        this.headers = createHeaders();
     }

    createEnviroment = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projenv`;
            const response = await axios.post(url, obj , this.headers);
            console.log("Respoonse",response)
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getEnviromentList(page,pageSize,sortColumn, sortDirection,filters) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projenv?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    async updateEnviroment(obj){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projenv`;
            const response = await axios.put(url,obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    async deleteEnviroment(id){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/projenv/${id}`;
            const response = await axios.delete(url,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async CheckEnviroment(obj){
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/checkConnection`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

   
}


export default new EnviromentService();