import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Button, IconButton, InputAdornment ,FormControl,Tooltip, Modal,FormHelperText ,Table, TableBody, Link ,TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import  {Link as RouterLink}  from "react-router-dom";
import dayjs from 'dayjs';
import { format } from 'date-fns';
import {modalPopUpStyleLarge } from '../../styles/getLPTheme';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { getSuitesTestCaseForDetailedReport } from "../../redux/actions/suites";
import { getSuiteReport } from "../../redux/actions/suiteReport";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DateFormatter from '../../components/DateFormatter';
import { connect } from "react-redux";
import Select from 'react-select';
import '../../styles/common.css';
import '../../styles/custom.css';
import { toast } from 'react-toastify';
import SuiteJobService from '../../services/suiteReport.service'
import DeleteIcon from '@mui/icons-material/Delete';
import showConfirmationAlert from "../../components/Confirm";


function DetailSuiteReport({ getSuitesTestCaseForDetailedReport ,getSuiteReport}) {
    const navigate = useNavigate();
    const [video,setVideo]=useState('')
    const [open_video, setOpenVideo] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [testcaseList, setTestcaseList] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState('');
    const [listOfSuiteJob , setlistOfSuiteJob] = useState([]);
    const [newData,setNewData] = useState('');
    const Auth = JSON.parse(localStorage.getItem("authentication"));
    useEffect(() => {
        getSuitesTestCaseForDetailedReport(0, 100, null, null, JSON.stringify({}),Auth["proj_id"]).then((res) => {
            setTestcaseList(res.rows);
            createDropDownList(res.rows);
        });
    }, [getSuitesTestCaseForDetailedReport,Auth["proj_id"]]);

    const createDropDownList = (allTestcaseList) => {
        let testcasesArray = [];
        if (allTestcaseList) {
            allTestcaseList.forEach((file) => {
                let obj = {
                    id: file.suite_id,
                    value: file.suite_name,
                    label: file.suite_name
                }
                testcasesArray.push(obj);
            });
        }
        setTestcaseList(testcasesArray);
    };
    
    const handleFromDateChange = (date) => {
        let fromdate = dayjs(date.$d)
        setFromDate(fromdate);
    };

    const clearFromDate = () => {
        setFromDate(null);
        setError('');
    };

    const handleToDateChange = (date) => {
        let toDate =  dayjs(date.$d)
        setToDate(toDate);
    };

    const navigateToSuiteTestCase = (row) => {
        navigate('/SuiteTestCaseReport', { state : row});
      }
    
      const deleteJob = (row) => {
        showConfirmationAlert('Are you sure you want to Delete ?')
        .then((result) => {
        if (result.isConfirmed) {
          SuiteJobService.deleteSuiteJob(row.id).then((res) => {
            console.log("Response",res.data.message)
            toast.success(res.data.message)
            getSuiteReport(newData).then(res => {
                setlistOfSuiteJob(res);
            });
          }).catch((error) => {
            console.error('Error:', error);
            });
        }
      });
    
      }
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (fromDate?.isAfter(toDate)) {
            toast.error('From Date cannot be greater than To Date.');
            setError("Error");
            return;
        }
        if(fromDate && !toDate ){
            toast.error('Both date ranges are mandatory');
            setError("Error");
            return;
        } else if(!fromDate && toDate ){
            toast.error('Both date ranges are mandatory');
            setError("Error");
            return;
        } 
         else {
            setError('');
            let obj = {
                from: fromDate ? fromDate.format('YYYY-MM-DD') : null,
                to: toDate ? toDate.format('YYYY-MM-DD') : null,
                suiteid: selectedOption ? selectedOption?.id : null,
                proj_id:Auth["proj_id"]
            };
            setNewData(obj)
            getSuiteReport(obj).then(res => {
                console.log(res.length)
                setlistOfSuiteJob(res);
                const message = res && res?.length > 0 ? "Record Found":"No record Found"
                toast.success(message)
            });
        }
    };

    

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        setSelectedOption(null);
        setlistOfSuiteJob([]);
    };

    const updatePopUpVideo = (video) => {
        setOpenVideo(true);
        setVideo(video)
      }
      const handleVideoClose = () => {
        setOpenVideo(false);
      };
    
    const tableRows = listOfSuiteJob && listOfSuiteJob.length > 0 ? (
        listOfSuiteJob.map((row, index) => (
          <TableRow className="td" key={index}>
            <TableCell>{row.suite_name}</TableCell>
            <TableCell>{row.total_test_cases}</TableCell>
            <TableCell><DateFormatter date={row.execution_time} /></TableCell>
            <TableCell><DateFormatter date={row.completion_time} /></TableCell>
            <TableCell>
              {row.status === 'running' ? (
                <div className="badge badge-opacity-warning text-capitalize">{row.status}</div>
              ) : row.status === 'stopped' ? (
                <div className="badge badge-opacity-warning text-capitalize">
                  {row.status}<StopIcon style={{ color: '#FF0000' }} />
                </div>
              ) : row.status === 'error' ? (
                <div className="badge badge-danger text-capitalize">
                  {row.status}<ErrorIcon style={{ color: '#B22222' }} />
                </div>
              ) : row.status === 'failure' ? (
                <div className="badge badge-danger text-capitalize">
                  {row.status}<ErrorIcon style={{ color: '#B22222' }} />
                </div>
              ) : (
                <div className="badge badge-opacity-success text-capitalize">
                  {row.status}<CheckCircleIcon style={{ color: '#008000' }} />
                </div>
              )}
            </TableCell>
            <TableCell>
              <Link onClick={() => navigateToSuiteTestCase(row)}>View Details</Link>
            </TableCell>
            <TableCell>
              {row.status !== 'running' && row.video_url && (
                <RouterLink onClick={() => updatePopUpVideo(row)} title="Play">
                  <SmartDisplayIcon style={{ color: 'red', cursor: 'pointer' }} />
                </RouterLink>
              )}
            </TableCell>
            {row.status !== 'running' &&(
            <TableCell>
              <Tooltip title="Delete">
                <DeleteIcon 
                  style={{ color: '#d71b1b' }} 
                  onClick={() => deleteJob(row)} 
                  className="action-icon clickIconClassDelete" 
                />
              </Tooltip>
            </TableCell>
            )}
          </TableRow>
        ))
      ) : (
        <TableRow>
            <TableCell colSpan={8} align="center">
                <span className='marginTop text-color-grey'>No Report available.</span>
            </TableCell>
        </TableRow>
      )

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='marginTop'>
                <h3 className='label-bold'>Detailed Suite Report</h3>
                <Card className='marginTop'>
                    <CardContent>
                        <form onSubmit={handleSubmit} className='flex-container marginTop'>
                            <div className='flex-item'>
                                <FormControl fullWidth>
                                    <label className='label-bold'>Suite list</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name="name"
                                        options={testcaseList}
                                        value={selectedOption}
                                        onChange={setSelectedOption}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            container: base => ({ ...base, width: '100%' }),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className='flex-item'>
                                <label className='label-bold'>From</label>
                                <DatePicker
                                    value={fromDate}
                                    onChange={handleFromDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(error)}
                                            helperText={error}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={clearFromDate}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                        />
                                    )}            
                                />
                                  {/* {error && <FormHelperText style={{color: 'red'}}>{error}</FormHelperText>} */}
                            </div>
                            <div className='flex-item'>
                            <label className='label-bold'>To</label>
                                <DatePicker
                                    value={toDate}
                                    onChange={handleToDateChange}
                                    dateFormat="yyyy-MM-dd"
                                />
                                {/* {error && <FormHelperText style={{color: 'red'}}>{error}</FormHelperText>} */}
                            </div>
                            <div className='flex-item marginTop'>
                                <Button type="submit">Submit</Button>
                            </div>
                            <div className='flex-item marginTop'>
                                    <Button onClick={handleReset} color="secondary">Reset</Button>
                                </div>
                        </form>
                    </CardContent>
                </Card>

                <br />
                <Card className='dashboard-table table td'>
                    <TableContainer className='dashboard-table  TableCell table-responsive '>
                        <Table >
                            <TableHead >
                                <TableRow className=' th'>
                                    <TableCell >Suite Name</TableCell>
                                    <TableCell >Total Cases</TableCell>
                                    <TableCell >Start Time</TableCell>
                                    <TableCell >Completion Time</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell >Details</TableCell>
                                    <TableCell >Video</TableCell>
                                    <TableCell >Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="td">
                                {tableRows}
                             
                             {/* { !listOfSuiteJob && <div className="col-md-12 text-center">
                                        <h5 className="p-4">
                                            No Suite report availaible
                                        </h5>
                                    </div>
                             } */}
                            </TableBody>
                        </Table>
                        <Modal
        open={open_video}
        onClose={handleVideoClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
           sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off">
         
          <div className="modal-header">
          <video controls style={{ maxWidth: '100%' }}>
                <source src={`${process.env.REACT_APP_API_URL}/api/v1/getVideo/${video.id}?token=${token}`} type="video/mp4" />
              </video>
          </div>

          <div className="modal-footer">
            <Button onClick={handleVideoClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>
                    </TableContainer>
                </Card>
            </div>
        </LocalizationProvider>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    getSuitesTestCaseForDetailedReport,
    getSuiteReport
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailSuiteReport);
