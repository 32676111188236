import {ASSIGN_USER_TO_TESTCASE, ASSIGNED_USER_LIST_TO_TESTCASE,USER_BY_PROJECT_ID} from "../actions/types";
  
  const initialState = {
    ProjuserList:null,
    TestCaseListByUser: null,
    assignUsers:null
  };
  
  const assignTestCase = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case ASSIGN_USER_TO_TESTCASE:
        return {
          ...state,
          TestCaseListByUser: payload,
        };
  
      case ASSIGNED_USER_LIST_TO_TESTCASE:
        return {
          ...state,
          assignUsers: payload,
        };
      case USER_BY_PROJECT_ID:
        return {
          ...state,
          ProjuserList: payload,
        };
  
      
      default:
        return state;
    }
  };
  export default assignTestCase;
  