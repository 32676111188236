import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, Button, CardContent, Typography, Divider , Tooltip } from '@mui/material';
import { getSuitesTestCaseId, updateSuitesTestCase, deleteSuitesTestCase ,updateTestCaseStatusById , getTestCaseNames } from '../../redux/actions/suites';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ClearIcon from '@mui/icons-material/Clear';
import '../../styles/custom.css';
import '../../styles/common.css';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';


const TestCasesView = ({ getSuitesTestCaseId, suitesTestList, updateSuitesTestCase, deleteSuitesTestCase , updateTestCaseStatusById , getTestCaseNames}) => {
  const [cellStyle] = useState({
    backgroundColor: '#ddd9d9',
    fontSize: '16px',
    fontWeight: 'bold'
  });
  const [suite,setSuite] = useState([])

  const [suite_Test_List, setSuiteTestList] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDragStart = (e, row) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(row));
  };
  useEffect(()=>{
    if(state){
      setSuite(state)
      getSuitesTestCaseId(state?.suite_id)
    }
  },[state])

  const goToAddCases = () => {
    navigate('/add-cases', { state: suite });
  };
  console.log("suite",suite)

  // useEffect(() => {

  //   getSuitesTestCaseId(suite.suite_id);
  // }, [suite]);

  useEffect(() => {
    const sortedStepsList = suitesTestList?.sort((a, b) => a.stc_prioprity - b.stc_prioprity);
    setSuiteTestList(sortedStepsList);
  }, [suitesTestList]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();
    const draggedRow = JSON.parse(e.dataTransfer.getData('text/plain'));
    const newData = [...suite_Test_List];
    const draggedIndex = newData.findIndex(item => item.stc_id === draggedRow.stc_id);
    newData.splice(draggedIndex, 1);
    newData.splice(newIndex, 0, draggedRow);

    newData.forEach((row, index) => {
      row.priority = index + 1;
    });

    setSuiteTestList(newData);
    let successMessage = false;
    newData.forEach((row, index) => {
      let obj = {
        id: row.stc_id,
        proj_id: row.proj_id,
        suite_id: row.suite_id,
        test_case_id: row.test_case_id,
        stc_prioprity: row.priority
      };
      updateSuitesTestCase(obj)
      .then(() => {
        if(!successMessage){
          toast.success("Testcases priorities has been updated successfully");
          successMessage = true;
        }
        navigate('/view-cases', { state: suite });
      });
    });
    getSuitesTestCaseId(suite.suite_id);
  };

  const updateSuitesTestStatus = ( id , event) => {
    let obj = {
      id: id ,
      status: event.target.checked
    }
    updateTestCaseStatusById(obj).then(() => {
      getSuitesTestCaseId(suite.suite_id);
    })
    .catch((error) => {
      console.error('Error:', error);
    }); 
  }

  const removeTestCaseFromList = (testCaseId) => {
    deleteSuitesTestCase(testCaseId).then(() => {
      getTestCaseNames(suite.suite_id).then((res) => {
        const sortedTestCaseList = res?.sort((a, b) => a.stc_prioprity - b.stc_prioprity);
        sortedTestCaseList.forEach((row, index) => {
          let obj = {
            id: row.stc_id,
            proj_id: row.proj_id,
            suite_id: row.suite_id,
            test_case_id: row.test_case_id,
            stc_prioprity: index + 1
          };
          updateSuitesTestCase(obj)
        });
        getTestCaseNames(suite.suite_id).then((res) => {
          const sortedTestList = res?.sort((a, b) => a.stc_prioprity - b.stc_prioprity);
          setSuiteTestList(sortedTestList);

        });
        getSuitesTestCaseId(suite.suite_id);
      })
    })
  }

  const navigateToTestScreen = (row) => {
    let obj = { case_id: '' }
    obj.case_id = row.test_case_id;
    navigate(`/testcase`, { state: obj });
  }

  const navigateToRunScreen = (suite_id) => {
    navigate(`/run-suites/${suite_id}`, { state: suite })
  }

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"  
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
         {suite?.suite_name} Testcases 
          <Button className='right'  onClick={goToAddCases}> + Add Test Cases</Button>
          <Button className='right'  colorStyle = 'primary' onClick={() => navigateToRunScreen(suite.suite_id)} disabled={suite_Test_List?.length === 0}><PlayCircleOutlineIcon/> Run Suites</Button>
        </Typography>
        <Divider></Divider>

        <CardContent>
        <TableContainer component={Card} className='dashboard-table table td'>
        <Table>
          <TableHead className='th'>
            <TableRow>
              <TableCell style={cellStyle}>Priority</TableCell>
              <TableCell style={cellStyle}>Case Name</TableCell>
              <TableCell style={cellStyle}>Project</TableCell>
              <TableCell style={cellStyle}>Total Steps</TableCell>
              <TableCell style={cellStyle}>Status</TableCell>
              <TableCell style={cellStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suite_Test_List?.map((row, index) => (
              <TableRow key={row.stc_id} draggable onDragStart={(e) => handleDragStart(e, row)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)} style={{ cursor: 'move' }}>
                <TableCell>{row.stc_prioprity}</TableCell>
                <TableCell>{row.case_name}</TableCell>
                <TableCell>{row.proj_name}</TableCell>
                <TableCell>{row.step_counter}</TableCell>
                <TableCell>
                  <Switch
                    checked={row.status}
                    onChange={(event) => updateSuitesTestStatus(row.stc_id , event)}
                    inputProps={{ 'aria-label': 'Switch A' }}
                  />
                </TableCell>
                <TableCell>
                  {/* <Button onClick={() => navigateToTestScreen(row)}>View Details</Button>
                  <Button colorStyle='cancel' onClick={() => removeTestCaseFromList(row.stc_id)}>Remove</Button> */}

                  <Tooltip title="View">
                        <RemoveRedEyeIcon className="action-icon" onClick={() => navigateToTestScreen(row)} />
                    </Tooltip>

                  <Tooltip title="Remove">
                        <ClearIcon className="action-icon-delete" onClick={() => removeTestCaseFromList(row.stc_id)} />
                    </Tooltip>
                    
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

        </CardContent>
      </Card>
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  suitesTestList: state?.suites.suiteListById
});

const mapDispatchToProps = {
  getSuitesTestCaseId,
  updateSuitesTestCase,
  deleteSuitesTestCase ,
  updateTestCaseStatusById,
  getTestCaseNames
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCasesView);
