import { CREATE_ENVIROMENT,
    ENVIROMENT_LIST,
    DELETE_ENVIROMENT,
    UPDATE_ENVIROMENT,  } from "../actions/types";
    
      const initialState = {
        EnviromentList: null,
      };
      
      const Enviroment = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          case ENVIROMENT_LIST:
            return {
              ...state,
              EnviromentList: payload,
              
          };          
            case CREATE_ENVIROMENT:
              return {
                ...state,
                isEnviromentCreated: true,
            };
            case DELETE_ENVIROMENT:
              return {
                ...state,
                isEnviromentDeleted: true,
            };
            case UPDATE_ENVIROMENT:
              return {
                ...state,
                isEnviromentUpdated: true,
            };

          default:
            return state;
        }
      };
      export default Enviroment;
      