import { ASSIGN_USER_TO_TESTCASE, ASSIGNED_USER_LIST_TO_TESTCASE,USER_BY_PROJECT_ID } from "./types";
import assignTestCaseService from "../../services/assignTestCase.service";
import usersService from "../../services/users.service";
import { toast } from 'react-toastify';



export const assignTestCaseToUsers = (object) => async (dispatch) => {
  try {
    let res = await assignTestCaseService.assignTestCaseToUser(object);
    if (res.status === 200) {
      dispatch({
        type: ASSIGN_USER_TO_TESTCASE,
        payload: res.data
      });
    } else {
      toast.error("Unable to assign project to user" + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateTestCaseAssignment = (object) => async (dispatch) => {
  try {
    let res = await assignTestCaseService.updateTestCaseAssignmentToUsers(object);
    if (res.status === 200) {
      dispatch({
        type: ASSIGN_USER_TO_TESTCASE,
        payload: res.data
      });
      toast.success("Project assigned successfully");
    } else {
      toast.error("Unable to assign project : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getTestCaseByUserId = () => async (dispatch) => {
    try {
      let res = await assignTestCaseService.getTestListUserWise();
      if (res.status === 200) {
        dispatch({
            type: ASSIGNED_USER_LIST_TO_TESTCASE,
            payload:res.data
        });
        // toast.success("Project assigned successfully");
      } else {
        toast.error("Unable to assign project : " + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };
export const getUserIdByProj = (projid) => async (dispatch) => {
    try {
      let res = await usersService.getuserbyproject(projid);
      if (res.status === 200) {

        dispatch({
            type: USER_BY_PROJECT_ID,
            payload:res.data
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };




