import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class SuiteService {
    constructor() {
        this.headers = createHeaders();
    }

    async getSuitesList(page,size,sortColumn, sortDirection,filters,proj_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suites?pageNo=${page}&size=${size}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters} &proj_id=${proj_id}` ;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    createSuites = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suites`;
            const response = await axios.post(url, obj, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    updateSuites = async (projectInfo) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suites`;
            const response = await axios.put(url, projectInfo, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    deleteSuites = async (suites_id) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suites/${suites_id}`;
            const response = await axios.delete(url,
                {
                    id: suites_id
                }, this.headers);
           
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    //--------------------------- Get Test Cases API / SuiteCases --------------------------

    async allTestCases(page, pageSize, sortColumn, sortDirection,filters,proj_id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/test_case?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}&proj_id=${proj_id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    //--------------------------- Suite Test case related API's--------------------------

    createSuiteTestCase = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suite_test_case`;
            const response = await axios.post(url, obj, this.headers);  
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    getSuiteTestCase = async () => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suite_test_case`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    getSuiteTestCaseById = async (suiteId) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/gettestbysuitesId/${suiteId}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    updateSuiteTestCaseById = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/updatetestbysuitetestId`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    updateSuiteTestCase = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suite_test_case`;
            const response = await axios.put(url, obj ,this.headers);    
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    deleteSuiteTestCase = async (suiteId) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/suite_test_case/${suiteId}`;
            let obj = {
                id: suiteId
            }
            const response = await axios.delete(url, obj ,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new SuiteService();