import React from 'react';
import { Card, CardContent, Button, TextField, Divider, Typography, Grid,Tooltip,Box } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect, } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../styles/project.css'
import { useLocation,useNavigate } from 'react-router-dom';
import jiratokenService from '../services/jiratoken.service';
import { createjiraticket, getticketList } from '../redux/actions/jira';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import showConfirmationAlert from "../components/Confirm";
import jiraService from '../services/jira.service';



const CreateJiraIssue = ({
    getticketList,
    createjiraticket, count,
    jiraList
}) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [key, setkey] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [Summary, setSummary] = useState(null);
    const [Type, setType] = useState('Task');
    const [Description, setDescription] = useState(null);
    const [loading, setloading] = useState(false);
    const [TicketsList, setTicketsList] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [video, setVideo] = useState(null);
    const [image, setImage] = useState(null);
    
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    const columns = [
        { field: "projectkey", headerName: "Key" },
        { field: "ticket_no", headerName: "Ticket No" },
        { field: "summary", headerName: "Summary" },
        { field: "issuetype", headerName: "Type" },
        { field: "jira_description", headerName: "Description" },
        { field: "assigne", headerName: "Assigne" },
        { field: "status", headerName: "Status" },
        { field: "u_name", headerName: "Created By" },

    ];
    const [width, setWidth] = useState(100 / columns.length + '% !important');



    const fetchJiraList = async () => {
            try {
                const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
                const obj = await jiratokenService.getjiraList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters)); 
                return obj.data;
            } catch (error) {
                console.error("Error fetching JIRA list:", error);  // Handle errors
            }  
    };


    useEffect(() => {
        const callFetchJiraList = async () => {
            const obj =await fetchJiraList();
            if (state) {
                
                if (obj?.count > 0) {
                    setDescription(state?.message);
                    setSummary(`${state?.TestcaseName} : ${state?.stepName}`);
                    setVideo(state?.video)
                    setImage(state?.image)
                    obj.rows?.filter((item) => {
                        if(item.isdefault){
                            setkey(item.projectkey)
                        } 
                    });
                } else {
                    showConfirmationAlert(
                        'No Jira Token Added',  // Title
                        'Click to add?',          // Text
                        'Add Jira Token',       // Confirm button text
                        'Cancel',                 // Cancel button text
                        '#34B1AA',                // Confirm button color
                        '#f44336c4'               // Cancel button color
                      )
                      .then((result) => {
                        if (result.isConfirmed) {
                          navigate('/JiraAccount')
                          
                        } else if (result.isDismissed) {
                            setDisabled(true)
                        }
                      });
                    
                }
            }else{
                if(obj?.count <= 0){
                    setDisabled(true)
                }else{
                    obj.rows.filter((item) => {
                        if(item?.isdefault){
                            setkey(item.projectkey)
                        } 
                    });
                   
                }
            }
        };
    
        callFetchJiraList(); // Invoke the async function
    }, [state]); // Add 'state' as a dependency

    useEffect(() => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        getticketList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
    }, [getticketList, paginationModals.page, paginationModals.pageSize]
    )

    const handleAccount=()=>{
        navigate('/JiraAccount')
    }

    useEffect(() => {
        const dataWithIds = jiraList?.map((row, index) => ({
            ...row,
            id: row.jira_id,
        }));
        setTicketsList(dataWithIds)
        
        setTotalCount(count);
    }, [jiraList]
    )

    const onChangekey = (event) => {
        setkey(event.target.value);
    }

    const onChangeSummary = (event) => {
        setSummary(event.target.value);
    }

    const onChangeType = (event) => {
        setType(event.target.value);
    }

    const onChangeDescription = (event) => {
        setDescription(event.target.value);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (key === '') {
            toast.error('Test Project Key can not be empty');
            return;
        }
        else if (Summary === '') {
            toast.error('Summary can not be empty');
            return;
        }
        else {
            const obj = {
                key: key,
                Summary: Summary,
                Description: Description,
                Type: Type
            }
            setloading(true);
            createjiraticket(obj)
                .then((res) => {
                    
                    let attachment={
                        filePath:video,
                        issueId:res.data.id,
                        image:image
                    }
                    
                    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
                    // setkey('');
                    jiraService.uploadattachment(attachment).then((response)=>{
                        console.log("upload",response)
                        
                    }).catch((error)=>{
                        console.error('Error:', error);
                    })
                    setType('Task');
                    setSummary('');
                    setDescription('');
                    getticketList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
                    setloading(false);
                    setImage('');
                    setVideo('');

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }
    return (
        <>
            <div>
                <Box>
                <Card
                    raised
                    sx={{
                        minWidth: "100%",
                        overflowX: "auto"
                    }}>
                   
                    <Typography variant="h5" sx={{
                        p: 2
                    }}>
                        Generate Ticket on Jira
                        <Tooltip title="Add Account">
                            <Button sx={'float: right ; marginBottom: 2px; font-weight: bold'} size={"small"} onClick={handleAccount}> + Add Jira Token</Button>
                        </Tooltip>
                    </Typography>
                    <Divider></Divider>
                    <CardContent>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                Jira Project Key:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField placeholder="Enter Project Key" required onChange={onChangekey} value={key} disabled />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Summary:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    <TextField placeholder="Enter Summary" onChange={onChangeSummary} value={Summary} />
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Description:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField placeholder="Enter Description" required onChange={onChangeDescription} multiline value={Description} />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    Issue Type:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Select style={{ width: '100%', height: '40px' }} onChange={onChangeType} value={Type} >
                                    <MenuItem value="Task">Task</MenuItem>
                                    <MenuItem value="Bug">Bug</MenuItem>
                                    <MenuItem value="Story">Story</MenuItem>

                                </Select>
                                {/* <Typography variant="subtitle2" fontSize={14}>
                                <TextField placeholder="Enter Issue Type"   onChange={onChangeType} />
                            </Typography> */}
                            </Grid>
                        </Grid>

                        <div style={{ marginBottom: '30px' }}>
                            <Button sx={{ float: 'right' }} onClick={onSubmit} disabled={disabled}>Generate Jira Ticket</Button>
                        </div>
                    </CardContent>

                </Card>
                </Box>
                <br></br>
                <Box>
                <Card
                    raised
                    sx={{
                        minWidth: "100%",
                        overflowX: "auto"
                    }}>
                    <Typography variant="h5" sx={{
                        p: 2
                    }}>
                        Generated Ticket List
                    </Typography>
                    <Divider></Divider>

                    <CardContent>
                        {TicketsList &&
                            <ServerPaginationGridNoRowCount
                                setPaginationModals={setPaginationModals}
                                paginationModals={paginationModals}
                                rowsData={TicketsList.length > 0 ? TicketsList : []}
                                total={count}
                                columns={columns}
                                columnWidth={width}
                                setSortModels={setSortModels}
                                sortModels={sortModels}
                                setFilterModels={setFilterModels}
                                filterModels={filterModels}
                            />}
                    </CardContent>

                </Card>
                </Box>
                
               
            </div>
            <div>


            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    jiraList: state.jira?.JiraList?.rows,
    count: state.jira?.JiraList?.count,

});

const mapDispatchToProps = {
    createjiraticket, getticketList
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJiraIssue);


