import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Checkbox, Button, Card, CardContent, Select, MenuItem, Typography } from '@mui/material';
import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import { getAllUsersProjects, getAllUsersList, assignProjectToUsers, getProjectsByUserId, deleteProjectsByUserId } from "../../redux/actions/assignUsers";
import { getuserList } from "../../redux/actions/manageuser";
import { getProjects } from "../../redux/actions/auth";
import { toast } from 'react-toastify';
import '../../styles/common.css';
import '../../styles/custom.css';

function AddUser({ projectsList, getAllUsersProjects, getProjects, project, usersList, getAllUsersList, assignProjectToUsers, getProjectsByUserId,
    deleteProjectsByUserId }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUser, setSelectedUser] = useState('select');

    useEffect(() => {
        getAllUsersProjects();
        getProjects(0, 0, null, null, JSON.stringify({}));
        getAllUsersList();
    }, []
    )

    const handleRowSelect = (row, index) => {
        const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.proj_id === row.proj_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedRows, row];
        } else {
            newSelected = [...selectedRows.slice(0, selectedIndex), ...selectedRows.slice(selectedIndex + 1)];
        }
        setSelectedRows(newSelected);
        setSelectAll(newSelected.length === project.length);
    };

    const isRowSelected = (row) => {
        return selectedRows.some(selectedRow => selectedRow.proj_id === row.proj_id);
    };


    const assignUsers = () => {
        if (!selectedUser || selectedUser === 'select') {
            toast.error("Please select user before assignment");
            return;
        }
        else {
            if (selectedRows.length > 0) {
                let successShown = false;
                deleteProjectsByUserId(selectedUser)
                    .then(() => {
                        selectedRows?.map((row, i) => {
                            let obj = {
                                user_id: selectedUser,
                                proj_id: row.proj_id
                            }
                            assignProjectToUsers(obj).then((res) => {
                                if (!successShown) {
                                    toast.success("Project is assigned to user successfully");
                                    successShown = true;
                                }
                            })
                        })
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else {
                toast.error("Please select project before assigning the users");
            }
        }
    }

    const handleSelectChange = (event) => {
        const id = event.target.value;
        setSelectedUser(id);
        setSelectedRows([]);
        getProjectsByUserId(id)
            .then((res) => {
                const commonElements = project?.filter(obj1 =>
                    res?.some(obj2 => obj1.proj_id === obj2.up_project_id_fk)
                );

                const newSelectedRows = commonElements.map(item => ({
                    proj_id: item.proj_id,
                    proj_name: item.proj_name,
                    testcase_counter: item.testcase_counter
                }));
                setSelectedRows(newSelectedRows);

                setSelectAll(newSelectedRows.length === project.length);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    User List
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <div className='marginTop'>
                        <label className='label-bold marginRight'>User Name</label>
                        <Select className="form-control" onChange={handleSelectChange} value={selectedUser} sx={{ width: '280px' }}>
                            <MenuItem value="select" key="" disabled="true">Select User</MenuItem>
                            {usersList?.map((option, index) => (
                                <MenuItem value={option?.u_id} key={index}>{option?.u_name}</MenuItem>
                            ))}
                        </Select>
                        <br></br>
                    </div>
                    <br></br>
                </CardContent>
            </Card>
            <br></br>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Assign Project To User
                    <Button sx={"float: right ; marginBottom: 2px"} onClick={assignUsers}> + Assign Project</Button>
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <TableContainer component={Card} className='dashboard-table table td'>
                        <Table>
                            <TableHead className='th'>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Total Test</TableCell>
                                    <TableCell>Created By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {project?.map((row, index) => (
                                    <TableRow
                                        key={row.proj_id}
                                        hover
                                        onClick={() => handleRowSelect(row, index)}
                                        role="checkbox"
                                        aria-checked={isRowSelected(row)}
                                        selected={isRowSelected(row)}
                                    >

                                        <TableCell padding="checkbox">
                                            <Checkbox checked={isRowSelected(row)} />
                                        </TableCell>
                                        <TableCell >{row.proj_name}</TableCell>
                                        <TableCell >{row.testcase_counter}</TableCell>
                                        <TableCell>{row.u_name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}

const mapStateToProps = (state) => ({
    project: state?.auth?.listProject?.rows,
    usersList: state?.assignUsers?.usersList
});

const mapDispatchToProps = {
    getAllUsersProjects,
    getProjects,
    getuserList,
    getAllUsersList,
    assignProjectToUsers,
    getProjectsByUserId,
    deleteProjectsByUserId
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);


