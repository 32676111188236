import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class FilesService{
    constructor() {
        this.headers = createHeaders();
     }

    async getAllFiles() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/files/get/all`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getStoredData(page, pageSize, sortColumn, sortDirection,filters) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/store_data?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


    uploadFile = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/files/create`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

   replaceFile = async (formdata , id) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/files/update/${id}`;
            const response = await axios.put(url,formdata ,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    deleteFile = async (id) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/files/file_delete/${id}`;
            const response = await axios.get(url,id,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    Checkduplicate = async (name) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/files/getbyname/${name}`;
            const response = await axios.get(url,name,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new FilesService();