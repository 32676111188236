import NotificationsIcon from '@mui/icons-material/Notifications';
import { List, ListItem, Divider,Button } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import NotificationService from '../services/notification.service'

export const getJobList = async () => {
  try {
    const res = await NotificationService.getNotificationList(); // Add await here
    return res.data.rows; // Return the job list
  } catch (error) {
    console.error('Error marking jobs as read:', error);
    return []; // Return an empty array or handle the error as needed
  }
};



function Notification() {
    const [toggle, setToggle] = useState(false);
    const [jobData, setJobList] = useState([]);
    const [jobCount,setJobCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setToggle(false);
        }
    };


    const fetchAndSetJobs = async () => {
      try {
        const jobList = await getJobList();
        console.log("Fetching jobs...");
        setJobList(jobList);
        setJobCount(jobList?.filter(job => job.is_read === false).length);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    useEffect(() => {
      const intervalId = setInterval(() => {
        fetchAndSetJobs();
      }, 5000);
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, []);


    const MarkAsRead = async () => {
      try {
        await NotificationService.updateNotification();
        await fetchAndSetJobs(); // Re-fetch jobs after marking as read
        
      } catch (error) {
       
      }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const data = jobData?.length > 0 ? (
        <div>
          <List>
            {jobData?.slice(0, 5).map((job, index) => (
              <React.Fragment key={index}>
                <ListItem>{job.message}</ListItem>
                <Divider sx={{ borderBottomWidth: '3px' }} /> {/* Divider to separate items */}
              </React.Fragment>
            ))}
          </List>
          <Button 
            sx={{ marginTop: '10px' }} 
            onClick={MarkAsRead}
          >
            Mark All as Read
          </Button>
        </div>
      ) : (
        <div>No running jobs</div>
      );

    return (
        <div style={{ position: 'relative', display: 'inline-block' }} ref={dropdownRef}>
            <NotificationsIcon onClick={() => setToggle(!toggle)} style={{ position: 'relative', fontSize: '30px', cursor: 'pointer' }} />
            
            {jobCount > 0 && <span style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                background: 'red',
                color: 'white',
                borderRadius: '100%',
                padding: '3px 7px',
                fontSize: '12px',
                fontWeight: 'bold',
                zIndex: 2
            }}>
                {jobCount}
            </span>}


            {toggle && (
                <div style={{
                    position: 'absolute',
                    zIndex: 1,
                    color: '#34B1AA',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    width: '300px',
                    right: '100%',
                    marginRight: '-20px',
                }}>
                    {loading ? (
                        <div>
                            <span style={{ color: 'black' }}>Loading...</span>
                        </div>
                    ) : (
                        data
                    )}
                </div>
            )}
        </div>
    );
}

export default Notification;
