import { GET_FILES_LIST , REPLACE_FILE , UPLOAD_FILE , DELETE_FILE ,CHECK_DUPLICATE} from "./types";
import FilesService from "../../services/files.service";
import { toast } from 'react-toastify';

export const getFilesList = () => async (dispatch) => {
    try {
        let res = await FilesService.getAllFiles();
        if (res.status === 200) {
           return res.data
        } else {
            toast.error("Unable to fetch list of files " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const uploadFiles = (object) => async (dispatch) => {
    try {
        let res = await FilesService.uploadFile(object);
        if (res.status === 200) {
            toast.success("File uploaded successfully");
            return res;
        } else {
            toast.error("Unable to upload file" + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const replaceFiles = (formdata , fileId) => async (dispatch) => {
    try {
        let res = await FilesService.replaceFile(formdata , fileId);
        if (res.status === 200) {
            dispatch({
                type: REPLACE_FILE,
                payload: res.data
            });
            toast.success("Files replaced successfully");
        } else {
            toast.error("unable to replaced files" + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const deleteFile = (id) => async (dispatch) => {
    try {
        let res = await FilesService.deleteFile(id);
        if (res.status === 200) {
            dispatch({
                type: DELETE_FILE,
                payload: res.data
            });
            toast.success("File deleted successfully");
        } else {
            toast.error("Unable to delete files" + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

export const Checkduplicate = (name) => async (dispatch) => {
    try {
        let res = await FilesService.Checkduplicate(name);
        if (res.status === 200) {
            dispatch({
                type: CHECK_DUPLICATE,
                payload: res.data
            });
            
            return res;
        }  
    } catch (error) {
        toast.error(error);
    }
};

export const getStoredData = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await FilesService.getStoredData(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
           return res.data
        } else {
            toast.error("Unable to fetch list of stored Data " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};

