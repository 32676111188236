import { GET_DASHBOARD, GET_SUITE_LIST, GET_DASHBOARD_BY_ID } from "./types";
import DashboardService from "../../services/dashboard.service";
import { toast } from 'react-toastify';

export const getDashboard = (proj_id) => async (dispatch) => {
  try {
    let res = await DashboardService.getUserDashboard(proj_id);
    if (res.status === 200) {
      dispatch({
        type: GET_DASHBOARD,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch dashboard details : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getsuitelist = (proj_id) => async (dispatch) => {
  try {
    let res = await DashboardService.getsuitelist(proj_id);
    if (res.status === 200) {
      dispatch({
        type: GET_SUITE_LIST,
        payload: res.data
      });
    } else {
      toast.error("Unable to fetch suite list : " + res.status);
    }
  } catch (error) {
    console.error("Suite list issue", error);
  }
};

export const getDashboardById = (id) => async (dispatch) => {
  try {
    let res = await DashboardService.getUserDashboardById(id);
    if (res.status === 200) {
      dispatch({
        type: GET_DASHBOARD_BY_ID,
        payload: res.data
      })
    }
  } catch (error) {
    console.error("error Not Found any ", error)
  }
};



