import {
    NOTIFICATION_LIST
  }
    from "./types";
  import NotificationService from "../../services/notification.service";

  import { toast } from 'react-toastify';
  

  
  export const updateNotification = () => async () => {
    try {
      let res = await NotificationService.updateNotification();
      if (res.status === 200) {
        console.log("Updated",res)
      } else {
        toast.error("Unable to update Notification" + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  
  export const deleteNotification = (id) => async (dispatch) => {
    try {
      let res = await NotificationService.deleteNotification(id);
      if (res.status === 200) {
        toast.success("Notification deleted Successfully");
      } else {
        toast.error("Unable to delete Notification : " + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  
  export const getNotificationList = () => async () => {
    try {
      let res = await NotificationService.getNotificationList();
      if (res.status === 200) {
        console.log(res.data.rows)
        return res.data.rows
        // dispatch({
        //   type: NOTIFICATION_LIST,
        //   payload: res.data.rows
        // });
      } else {
        toast.error("Unable to fetch suite list : " + res.status);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  
  