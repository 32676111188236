import { Link, Card, Select, Grid, MenuItem, CardContent, Table, Modal, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Tooltip } from '@mui/material';
import { testCaseList, createTestCase, deleteTestCaseById } from "../redux/actions/testcase";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import '../styles/custom.css';
import '../styles/common.css';
import moment from 'moment-timezone';
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AddUser } from "../redux/actions/manageuser";
import { getAllUsersList } from "../redux/actions/assignUsers";

import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import { toast } from 'react-toastify';
export const ProjectCases = ({ testCaseList, testList, updateTestCase, deleteTestCaseById, Listofuser, getAllUsersList, AddUser }) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [TestCase, setTestCase] = useState([]);
  const [user, setUserName] = useState('select');
  const navigate = useNavigate();
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [userdata]  = useState(JSON.parse(localStorage.getItem('authentication')));
  const [userType, setUserType] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [proj,setProj] = useState([])

  const [sortModels, setSortModels] = useState([]);
  const [filterModels, setFilterModels] = useState({ items: [] });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  useEffect(()=>{
    if(state){
      setProj(state)
    }
  },[state])

  useEffect(() =>{    
    if(userdata!=null)
    { 
      setUserType(userdata.is_admin);
       
    }
  }, [userdata]);
  const columns = [
    {
      field: "testid",
      headerName: "Id",
      renderCell: (params) => (
        <Tooltip title={params.row.testid}>
          <Link style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => (navigateToTestScreen(params.row))} title={params.row.testid}> {params.row.testid}</Link>
        </Tooltip>
      )
    },
    {
      field: "case_name",
      headerName: "TC Name",
      renderCell: (params) => (
        <Tooltip title={params.row.case_name}>
          {params.row.case_name}
        </Tooltip>
      )
    },
    {
      field: "count",
      headerName: "Test Steps",
    },
    {
      field: "case_updated_at",
      headerName: "Last Updated",
      renderCell: (params) => (
        formatDate2(params.row.case_updated_at)
      )
    },
    {
      field: "u_name",
      headerName: "Updated By",
    },
    
    {
      field: "assignuser",
      headerName: "Assigned To",
      renderCell: (params) => {
        if(userType){
          const row = params.row;
          return row.user_id ? (
            <Tooltip title={row.assignuser}>
              <Link className="action-icon-brand"  onClick={() => navigateToAssignTestCase()}>{row.assignuser}</Link>
            </Tooltip>
          ) : (
            <Tooltip title="Assign User">
              <PersonAddAltIcon className="action-icon-brand"  onClick={() => navigateToAssignTestCase()}/>
            </Tooltip>
          );

        }
        else{
          const row = params.row;
        return row.user_id ? (
          <Tooltip title={row.assignuser}>
            <Link className="action-icon-brand" >{row.assignuser}</Link>
          </Tooltip>
        ) :null
        }
        
      },
    },
    {
      field: "aciton",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <Tooltip title="Run job"><PlayCircleOutlineIcon className="action-icon-brand" onClick={() => navigateToRunJob(params.row)} /></Tooltip>
          <Tooltip title="Delete"><DeleteIcon className="action-icon-delete" onClick={() => deleteTestCase(params.row)} /></Tooltip>

        </>
      ),
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + '...';
  };

  const [width, setWidth] = useState(100 / columns.length + '% !important');

  useEffect(() => {
    if(state){
      setProj(state)
    
    getAllUsersList()
    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
    testCaseList(state?.proj_id, paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify({}));
  }}
  , [paginationModals, sortModels, filterModels,state]
  )

  const navigateToTestScreen = (row) => {
    navigate(`/testcase`, { state: row });
  }
  const handleClose = () => {
    setOpen(false);
    setTestCase('')
    setUserName('select')
  };
  const OpenPopUp = (row) => {
    setTestCase(row);
    setOpen(true);
    if (row.user_id) {
      setUserName(row.user_id)
    }

  }

  const deleteTestCase = (row) => {
    let obj = {
      id: row.case_id
    }
    deleteTestCaseById(obj).then(() => {
      // testCaseList(state?.proj_id);
      const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
      testCaseList(proj?.proj_id, paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify({}));
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  const formatDate2 = (dateString) => {
    // Format the date string using moment
    const authConfig = JSON.parse(localStorage.getItem('authentication'));
    const date_format = authConfig ? authConfig['date_time_format'] : 'YYYY-MM-DD HH:mm:ss'; // Default timezone
    const formattedDate = moment(dateString).format(date_format);

    return formattedDate;
  };

  const tableRows = testList?.rows?.map((row, index) => {
    return { ...row, id: index + 1 }
  });
  console.log("Test",testList)
  useEffect(() => {
    updateTableHeight(tableRows?.length);
  }, [paginationModals.pageSize, tableRows]);


  const handleUserChange = (event) => {
    setUserName(event.target.value);
  };
  const navigateToAddScreen = (row) => {
    const testCaseObject = {
      testCaseInfo: row,
      projectInfo: proj,
      isEditScreen: true
    }
    navigate(`/add-testcases`, { state: testCaseObject });
  }

  const navigateToRunJob = (row) => {
    navigate(`/runJob`, { state: row });
  }

  const navigateToAssignTestCase = () => {
  
    const projectInfo = proj;
    
    navigate(`/assign_testcase`, { state: projectInfo });
  }

  const Submit = (event) => {
    event.preventDefault();
    if (!user || user === "select") {
      toast.error("Please Select the User")
    } else {
      const obj = {
        userid: user,
        id: TestCase.case_id
      };
      AddUser(obj).then((res) => {
        setTestCase([]);
        setOpen(false);
        setUserName('select')
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        testCaseList(proj?.proj_id, paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify({}));
        toast.success("User Assigned Sucessfully")
      }).catch((error) => {
        console.error(error)
        toast.error("Error Occured while Updating User")

      })
    }

  }

  return (
    <div>
      <Card
        raised
        sx={{
          minWidth: "100%",
          overflowX: "auto"
        }}>
        <Typography variant="h5" sx={{
          p: 2
        }}>
          Test Cases ({proj?.proj_name})
          {userType && <Button sx={"float: right"} onClick={() => navigateToAssignTestCase()}> Assign Multiple Testcase</Button>}
          <Button sx={"float: right"} onClick={() => navigateToAddScreen()}> + Add Testcase</Button>
        </Typography>
        <Divider></Divider>
        <CardContent className='marginTop'>
          <div style={{ height: tableHeight, width: '100%' }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={tableRows && tableRows.length ? tableRows : []}
              total={testList && testList?.count  ? testList?.count  : 0}
              columns={columns}
              columnWidth={width}
              setSortModels={setSortModels}
              sortModels={sortModels}
              setFilterModels={setFilterModels}
              filterModels={filterModels}
            />
          </div>
          
        </CardContent>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          className="modalContainer">
          <Box
            component="div"
            className="modalContent"
            noValidate
            autoComplete="off"
          >
            {/* Header */}
            <div className="modal-header">
              <h3 className='label-bold'>
                Assign User
              </h3>
            </div>
            {/* Content */}
            <div className="modal-body">


              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" fontSize={14}>
                    Test Case:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={TestCase.case_name}>
                    <b>{truncateText(TestCase.case_name, 20)}</b>
                  </Tooltip>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>

                <Grid item xs={4} sm={2}>
                  <Typography variant="subtitle2" fontSize={14}>
                    User:
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Select className="form-control" onChange={handleUserChange} value={user} sx={{ width: '280px' }}>
                    <MenuItem value="select" key="" disabled="true">Select User</MenuItem>
                    {Listofuser?.map((option, index) => (
                      <MenuItem value={option?.u_id} key={index}>{option?.u_name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
            {/* Footer */}
            <div className="modal-footer">
              <Button onClick={Submit} className="modalButton modalCloseButton">Submit</Button>
              <Button colorStyle='cancel' onClick={handleClose} >Close</Button>
            </div>
          </Box>
        </Modal>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  testCaseList: state.auth?.testCaseList,
  testList: state.auth?.testList,
  Listofuser: state?.assignUsers?.usersList
});

const mapDispatchToProps = {
  testCaseList,
  createTestCase,
  deleteTestCaseById,
  getAllUsersList,
  AddUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCases);