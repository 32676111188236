import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button , TableContainer , Card } from '@mui/material';
import { connect } from "react-redux";
import { getAllTestCaseForSuites, createSuiteTestCases , getSuitesTestCaseId} from "../../redux/actions/suites";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

function AllCases({ getAllTestCaseForSuites, testCaseList, createSuiteTestCases, suitesTestList , getSuitesTestCaseId}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [testList, setTestCase] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [suite,setSuite] = useState([])
  const Auth = JSON.parse(localStorage.getItem("authentication"));
  useEffect(()=>{
    if(state){
      setSuite(state)
    }
  },[state])

  useEffect(() => {
    getAllTestCaseForSuites(0,0,null,null,JSON.stringify({}),Auth["proj_id"]);
  }, [Auth["proj_id"]]
  )

  useEffect(() => {
    if (testCaseList && testCaseList.length) {
      const filterarray=testCaseList?.filter(item=>item.count>0)
      const filteredArray1 = filterarray?.filter(item => !suitesTestList.map(rm => rm.test_case_id).includes(item.case_id));

      setTestCase(filteredArray1);
    }
  }, [testCaseList]
  )

  const handleRowSelect = (row, index) => {
    const selectedIndex = selectedRows.findIndex(selectedRow => selectedRow.test_case_id === row.case_id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, {
        proj_id: suite.proj_id,
        suite_id: suite.suite_id,
        test_case_id: row.case_id,
        stc_prioprity: 0
      });
    } else {
      newSelected = [...selectedRows.slice(0, selectedIndex), ...selectedRows.slice(selectedIndex + 1)];
    }
  
    setSelectedRows(newSelected);
    setSelectAll(newSelected.length === testCaseList.length);
  };
  

  const isRowSelected = (row) => {
    return selectedRows.some(selectedRow => selectedRow.test_case_id === row.case_id);
  };
  
  const handleSelectAllClick = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(testCaseList.map(row => row.case_id));
    }
    setSelectAll(!selectAll);
  };

  const addTestCase = () => {
    if(selectedRows?.length <= 0){
      toast.info("Please select alteast 1 test cases before proceeding");
    }
   else{
    let successMessage = false;
      selectedRows?.map((obj, i) => {
        obj.stc_prioprity = (suitesTestList.length + i) + 1
        createSuiteTestCases(obj)
          .then(() => {
            if(!successMessage){
              toast.success("Testcase(s) added successfully to the suite");
              successMessage = true;
            }
             //getSuitesTestCaseId(state.suite_id);
          })
      })
     
      navigate('/view-cases', { state: state });
    }
  }

  return (
    <div className='marginTop'>
      <h3 className='label-bold'>Add Testcases for {suite?.suite_name}</h3>    
      <Button sx={"float: right ; marginBottom: 2px"} onClick={addTestCase}> + Add Test Cases</Button>
      <br></br>
      <br></br>
      <TableContainer component={Card} className='dashboard-table table td'>
          <Table>
            <TableHead className='th'>
          <TableRow>
            <TableCell padding="checkbox">
            </TableCell>
            <TableCell>Testcase Name</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>Total Steps</TableCell>
            <TableCell>Created By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testList?.map((row, index) => (
            <TableRow
              key={row.case_id}
              hover
              onClick={() => handleRowSelect(row, index)}
              role="checkbox"
              aria-checked={isRowSelected(row)}
              selected={isRowSelected(row)}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isRowSelected(row)} />
              </TableCell>
              <TableCell >{row.case_name}</TableCell>
              <TableCell >{row.proj_name}</TableCell>
              <TableCell >{row.count}</TableCell>
              <TableCell>{row.u_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  testCaseList: state.suites?.testCaseListSuites?.rows,
  suitesTestList: state?.suites?.suiteListById
});

const mapDispatchToProps = {
  getAllTestCaseForSuites,
  createSuiteTestCases,
  getSuitesTestCaseId
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCases);

