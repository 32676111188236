import { Card, CardContent, Table, TableBody, TableCell, TableRow, Button, Modal, Box, Grid, Typography, TextField } from '@mui/material';
import { getDashboard, getDashboardById } from "../redux/actions/dashboard";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import * as React from 'react';
import { Chart } from 'chart.js';
import { CategoryScale, LinearScale, BarController, LineController, Title, PointElement, LineElement, BarElement, ArcElement, Tooltip } from 'chart.js';
import '../styles/custom.css';
import '../styles/style.css';
import '../styles/common.css';
import html2pdf from 'html2pdf.js';
import { useLocation, useNavigate } from 'react-router-dom';
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BarChart from "../components/BarChart";
import LineChart from "../components/LineChart";
import DoughnutChart from "../components/DoughnutChart";
import DateFormatter from '../components/DateFormatter';
import { modalPopUpStyleLarge } from '../styles/getLPTheme';
import { toast } from 'react-toastify';
import SuiteJobService from '../services/suiteReport.service'
import { FiDownload } from "react-icons/fi";
import { IoIosSend } from "react-icons/io";





Chart.register(CategoryScale, LinearScale, BarController, LineController, Title, PointElement, LineElement, BarElement, ArcElement, Tooltip);

export const DashboardReport = ({ getDashboard, dashboardList, getDashboardById }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [recipient, setrecipient] = useState('');
  const [Body, setBody] = useState('');
  const [jobName, setJobName] = useState('');
  const [cellStyle, setCellStyle] = useState({
    backgroundColor: '#ddd9d9',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold'
  });
  const { state } = useLocation();

  useEffect(() => {

    getDashboardById(state);

  }, [])

  const handleClose = () => {
    setOpen(false);
  };
  const OpenAttachment = () => {
    setOpen(true);
  };


  var latestSuite = []
  var testCase = []
  var latest = []
  for (let item in dashboardList) {
    latest.push(dashboardList[item])
    testCase.push(dashboardList[item].sel_logs)
    const suiteId = dashboardList[item].id
    const name = dashboardList[item].suite_name
    var datetimeString = dashboardList[item].execution_time;
    var datetime = new Date(datetimeString);
    const execution_time = `${datetime.toLocaleDateString('en-GB')} ${datetime.toLocaleTimeString('en-GB')}`;
    datetimeString = dashboardList[item].completion_time
    datetime = new Date(datetimeString);
    const completion_time = `${datetime.toLocaleDateString('en-GB')} ${datetime.toLocaleTimeString('en-GB')}`;

    let doughnut_pass = 0
    let doughnut_failed = 0
    let doughnut_stop = 0
    if (dashboardList[item].test_status === "success") {
      doughnut_pass = dashboardList[item].passed;
    } else if (dashboardList[item].test_status === "failure") {
      doughnut_failed = dashboardList[item].total_cases - dashboardList[item].passed;
    } else {
      let obj = dashboardList[item].sel_logs
      let total = dashboardList[item].total_cases

      for (let item2 in obj) {

        if (obj[item2].test_status === "success") {
          doughnut_pass = doughnut_pass + 1
        } else if (obj[item2].test_status === "failure") {
          doughnut_failed = doughnut_failed + 1
        } else {
          doughnut_stop = doughnut_stop + 1
        }

      }
      doughnut_stop += total - (doughnut_pass + doughnut_failed + doughnut_stop)

    }
    const total_cases = dashboardList[item].total_cases
    const executionTime = new Date(dashboardList[item].execution_time).getTime();
    const completionTime = new Date(dashboardList[item].completion_time).getTime();
    const timeDifference = Math.abs(completionTime - executionTime);

    const totalSeconds = timeDifference / 1000;

    const formattedTime = `${Math.floor(totalSeconds / 3600)}:${Math.floor((totalSeconds % 3600) / 60)}:${(totalSeconds % 60).toFixed(2)}`;

    const avg_time = formattedTime
    const pass_percent = Math.round((dashboardList[item].passed / dashboardList[item].total_cases) * 100)
    latestSuite.push(name, execution_time, completion_time, doughnut_pass, doughnut_failed, avg_time, total_cases, suiteId, pass_percent, doughnut_stop)
    break;
  }
  const [subject, setSubject] = useState('');

  useEffect(() => {
    setJobName(latestSuite[0])

  }, [latestSuite])

  useEffect(() => {
    setSubject(`${jobName} Execution Report`)
  }, [jobName])

  const onChangeSubject = (event) => {
    setSubject(event.target.value)
  }

  const testCasetable = testCase[0]?.map((row, index) => {

    return (
      <tr>
        <TableCell>{row.case_name}</TableCell>
        <TableCell>{row.total_steps}</TableCell>
        <TableCell><DateFormatter date={row.execution_time} /></TableCell>
        <TableCell><DateFormatter date={row.completion_time} /></TableCell>
        <TableCell>
          {row.test_status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.test_status}</div>) :
            row.test_status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.test_status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
              row.test_status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.test_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                row.test_status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.test_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                  (<div className="badge badge-opacity-success text-capitalize">{row.test_status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
        </TableCell>
        {/* <TableCell>{row.test_status === 'failure' ? "Completely Failed" : "Completely Successfully" }
                  {row.test_status === 'success' && <BsCheckLg style={{ color: 'green', fontSize: '24px'}}/>}
                  {row.test_status === 'failure' && <IoMdClose style={{ color: 'red', fontSize: '24px'}}/>}</TableCell> */}


      </tr>
    )
  });

  const last = latest?.map((row, index) => {

    return (
      <TableRow>
        <TableCell>{row.suite_name}</TableCell>
        <TableCell><DateFormatter date={row.execution_time} /></TableCell>
        <TableCell><DateFormatter date={row.completion_time} /></TableCell>
        <TableCell><div>
          <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
            <p className="text-success">{row.pass_percent}%</p>
            <p >{row.passed}/{row.total_cases}</p>
          </div>

          <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100">
            <div style={{ width: `${row.pass_percent}%`, backgroundColor: 'green' }}></div>
          </div>
        </div>
        </TableCell>
        <TableCell>
          {row.suite_status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}</div>) :
            row.suite_status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
              row.suite_status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                row.suite_status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                  (<div className="badge badge-opacity-success text-capitalize">{row.suite_status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
        </TableCell>
        {/* <TableCell>{row.suite_status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}</div>) :
                row.suite_status === 'stop' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}</div>) : 
                row.suite_status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.suite_status}</div>) : 
                (<div className="badge badge-opacity-success text-capitalize">{row.suite_status}</div>)}
            </TableCell> */}


      </TableRow>

    )
  });

  const tableRows = dashboardList?.map((row, index) => (
    <TableRow>
      <TableCell>{row.suite_name}</TableCell>
      <TableCell><DateFormatter date={row.execution_time} /></TableCell>
      <TableCell><DateFormatter date={row.completion_time} /></TableCell>
      <TableCell><div>
        <div className="d-flex justify-content-between align-items-center mb-1 max-width-progress-wrap">
          <p className="text-success">{row.pass_percent}%</p>
          <p >{row.passed}/{row.total_cases}</p>
        </div>

        <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25"
          aria-valuemin="0" aria-valuemax="100">
          <div style={{ width: `${row.pass_percent}%`, backgroundColor: 'green' }}></div>
        </div>
      </div>

      </TableCell>
      <TableCell>
        {row.suite_status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}</div>) :
          row.suite_status === 'stopped' ? (<div className="badge badge-opacity-warning text-capitalize">{row.suite_status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
            row.suite_status === 'error' ? (<div className="badge badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
              row.suite_status === 'failure' ? (<div className="badge badge-danger text-capitalize">{row.suite_status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                (<div className="badge badge-opacity-success text-capitalize">{row.suite_status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
      </TableCell>

    </TableRow>
  ));



  const navigateToDashboard = () => {
    navigate('/dashboard');
  }

  const onChangerecipient = (event) => { setrecipient(event.target.value); };
  const onChangeEmailBody = (event) => {
    setBody(event.target.value)
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateEmailList = (emailList) => {
    const emails = emailList.split(',').map(email => email.trim());
    return {
      isValid: emails.every(validateEmail),
      invalidEmails: emails.filter(email => !validateEmail(email))
    };
  };

  const emailAttachment = async (e) => {
    e.preventDefault();
    const attachment = await getPDFBlob();
    const formData = new FormData();

    if (subject === '' || subject === null) {
      toast.error("Subject can not be empty");
      return;
    }

    if (recipient === '' || recipient === null) {
      toast.error("Email can not be empty");
      return;
    }

    // Validate the recipient emails
    const { isValid, invalidEmails } = validateEmailList(recipient);

    if (!isValid) {
      toast.error(`Invalid email(s): ${invalidEmails.join(', ')}`);
      return;
    }

    // Proceed only if all validations pass
    formData.append('subject', subject);
    formData.append('recipient', recipient);
    formData.append('bodyContent', Body);
    formData.append('file', attachment, `${latestSuite[0]}.pdf`);

    SuiteJobService.sendReport(formData)
      .then((res) => {
        console.log("response from server", res);
        if (res) {
          setOpen(false);
          setrecipient('');
          setBody('');
          toast.success('Email Sent Successfully');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Failed to send email. Please try again.');
      });
  };





  const getPDFBlob = () => {
    const element = document.getElementById('report');
    const options = {
      filename: `${latestSuite[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'A3', orientation: 'portrait' },
    };

    // Return a promise that resolves with the Blob of the PDF
    return new Promise((resolve, reject) => {
      html2pdf()
        .from(element)
        .set(options)
        .outputPdf('blob') // Generate the Blob
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleDownload = () => {
    const element = document.getElementById('report');
    const options = {
      filename: `${latestSuite[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'A3', orientation: 'portrait', margin: { top: 1, right: 0.5, bottom: 1, left: 0.5 } }
    };

    html2pdf().from(element).set(options).save();
  }




  return (
    <><div style={{ margin: '10px' }}>
      <div>
      <Button
        onClick={handleDownload}
        style={{ alignItems: 'center', marginRight: '16px' }}
      >
        <FiDownload style={{ marginRight: '8px' }} />
        Download
      </Button>
      <Button
        onClick={OpenAttachment}
        style={{alignItems: 'center' }}
      >
        Send
        <IoIosSend style={{ marginLeft: '8px' }} />
      </Button>
    </div>
      
      <br />
      <Button onClick={navigateToDashboard} colorStyle="cancel">Back</Button>
      

    </div>
      <div id="report" style={{ marginLeft: '5px', width: '100%' }}>
        <div className="col-12 col-lg-12 d-flex flex-column">
          <p
            style={{ whiteSpace: 'nowrap', padding: '15px 0px', textAlign: 'center', fontSize: '3vw', lineHeight: '34px', margin: '0px', fontFamily: 'Arial' }}>
            Suite Execution Report
          </p>
          <p
            style={{ whiteSpace: 'nowrap', padding: '2vw 0px', fontSize: '2vw', color: '#1F1F1F', lineHeight: '22px', margin: '0px 0px 0px 0px', fontFamily: 'Arial' }}>
            Test Suite:{latestSuite[0]}
          </p>
        </div>
        <div className="row" style={{ border: '#d6d6d6' }}>
          <div className="col-lg-12 d-flex flex-column" style={{ width: '98%' }}>
            <div className=" col-12 grid-margin stretch-card card card-rounded card-body"
              style={{ border: '1px solid #d6d6d6' }}>
              <h4 className=" pull-left" style={{ fontWeight: 700 }}>Run Status :</h4>
              <div className="dashboard-table">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>Suite Name</th>
                      <th style={{ width: '20%' }}>Start Time</th>
                      <th style={{ width: '20%' }}>Completion Time</th>
                      <th style={{ width: '20%' }}>Progress</th>
                      <th style={{ width: '20%' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {last}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-body" style={{ width: '98%', border: '1px solid #d6d6d6' }}>
              <h4 style={{ fontWeight: 700, float: 'left' }}>Brief Summary:</h4>
              <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '2vw' }}>
                <tr style={{ backgroundColor: 'white' }}>

                  <th className="col-lg-8"
                    style={{ width: '70%', margin: '2vw', borderBottom: '1px solid white', border: '1px solid #d6d6d6', borderRadius: '10px' }}>
                    <div className="statistics-details d-flex align-items-center"
                      style={{ margin: '1vw', justifyContent: 'space-around' }}>
                      <div>
                        <p>Suite Name</p>
                        <h3>{latestSuite[0]}</h3>
                      </div>
                      <div style={{ color: 'blue' }}>
                        <p className="statistics-title">Total Cases</p>
                        <h3 className="rate-percentage">{latestSuite[6]}</h3>
                      </div>
                      <div style={{ color: 'green' }}>
                        <p className="statistics-title">Passed</p>
                        <h3 className="rate-percentage">{latestSuite[3]}</h3>
                      </div>
                      <div style={{ color: 'red' }}>
                        <p>Failed</p>
                        <h3 className="rate-percentage">{latestSuite[4]}</h3>
                      </div>
                      <div style={{ color: 'rgb(228, 208, 10)' }}>
                        <p>Stopped</p>
                        <h3 className="rate-percentage">{latestSuite[9]}</h3>
                      </div>
                    </div>
                  </th>
                  <th className="col-lg-4" style={{ borderBottom: '1px solid white', border: '1px solid #d6d6d6', borderRadius: '10px' }}>
                    <div style={{ width: '100%', height: '20%', padding: '5%' }}>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="card-title card-title-dash">Last Suite Run</h4>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10%', margin: '10%' }}>
                        <DoughnutChart dashboardList={dashboardList} />
                      </div>
                      <h4 style={{ float: 'left' }} id="percent_pass"> Passed :{latestSuite[8]}%</h4>
                    </div>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: '2vw' }}>
          <div className="col-lg-12 d-flex flex-column" style={{ width: '98%' }}>
            <div className=" col-12 grid-margin stretch-card card card-rounded card-body"
              style={{ border: '1px solid #d6d6d6' }}>
              <h4 className=" pull-left" style={{ fontWeight: 700 }}>Suite Last 5 Run Summary :</h4>
              <div className="dashboard-table">
                <table style={{ width: '100%' }} className="dashboard-table table-responsive">
                  <thead className="th">
                    <tr>
                      <th >Suite Name</th>
                      <th >Start Time</th>
                      <th >Completion Time</th>
                      <th >Progress</th>
                      <th >Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tableRows}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: '2vw' }}>
          <div className="col-lg-12 d-flex flex-column" style={{ width: '98%' }}>
            <div className=" col-12 grid-margin stretch-card card card-rounded card-body"
              style={{ border: '1px solid #d6d6d6' }}>
              <h4 className=" pull-left" style={{ fontWeight: 700 }}>Detailed Summary of Test Cases:</h4>
              <div className="dashboard-table">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>Test Case Name</th>
                      <th style={{ width: '20%' }}>Total Steps</th>
                      <th style={{ width: '20%' }}>Start Time</th>
                      <th style={{ width: '20%' }}>Completion Time</th>
                      <th style={{ width: '20%' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>{testCasetable}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card card-body" style={{ width: '98%', border: '1px solid #d6d6d6' }}>

              <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '2vw' }}>
                <tr style={{ backgroundColor: 'white' }}>
                  <th className="col-lg-6" style={{ width: '50%', margin: '2vw', borderBottom: '1px solid white', border: '1px solid #d6d6d6', borderRadius: '10px' }}>
                    <div style={{ width: '100%', height: '300px' }}>
                      {/* <Bar data={data2} options={options2} /> */}
                      <BarChart Data={dashboardList} />
                    </div>
                  </th>
                  <th className="col-lg-6" style={{ borderBottom: '1px solid white', border: '1px solid #d6d6d6', }}>
                    <div style={{ width: '100%' }}>

                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <LineChart dashboardList={dashboardList} />
                      </div>
                    </div>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>


      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off">

          <div className="modal-header">
          <Typography variant="h5" >Send Report To Recipients</Typography>
          </div>
          <form onSubmit={emailAttachment}>
            <Grid container sx={{ p: 2 }}>
              <Grid item sm={2}>
                <Typography variant="subtitle2" lineHeight={3.0} fontSize={14}>
                  Subject:
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <Typography variant="subtitle2" fontSize={14}>
                  <TextField
                    className='input'
                    value={subject}
                    onChange={onChangeSubject}
                    fullWidth
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 2 }}>
              <Grid item sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Recipient Email:
                </Typography>
              </Grid>
              <Grid item xs={4} sm={10}>
                <Typography variant="subtitle2" fontSize={14}>
                  <TextField
                    className='input'
                    value={recipient}
                    onChange={onChangerecipient}
                    style={{ width: '100%' }}
                    multiline
                  />
                </Typography>
                <span>
                  <h6>Note:- use comma (,) separator for multiple recipients</h6>
                </span>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 2 }}>
              <Grid item sm={2}>
                <Typography variant="subtitle2" fontSize={14}>
                  Body:
                </Typography>
              </Grid>
              <Grid item sm={10}>
                <Typography variant="subtitle2" fontSize={14}>
                  <TextField
                    className='input'
                    value={Body}
                    onChange={onChangeEmailBody}
                    style={{ width: '100%' }}
                    minRows={6}
                    multiline
                  />
                </Typography>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Grid container style={{ paddingTop: '8px', display: 'flex', flexDirection: 'row' ,justifyContent: 'flex-end'}}>
                    <Grid item>
                        <Button type="submit">Submit</Button>
                        <Button onClick={handleClose} colorStyle="cancel">Close</Button>
                    </Grid>
                </Grid>
            </div>
            
          </form>

        </Box>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({

  dashboardList: state.dashboard?.dashboard
});



const mapDispatchToProps = {

  getDashboardById,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardReport);

