import React, { useState, useEffect } from 'react';
import { Card, Table, TableBody, MenuItem, FormControl, InputAdornment, TableCell, TableContainer, IconButton, TableHead, TableRow, Button, CardContent } from '@mui/material';
import { getPersonaList, createPersona, updatePersona, deletePersona } from "../redux/actions/environmentPersona";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { connect } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import '../styles/project.css';
import '../styles/custom.css';
import '../styles/common.css';
import '../styles/files.css';
import EnviromentService from '../services/enviroment.service';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import showConfirmationAlert from "../components/Confirm";



const Persona = ({ ListData, ListData2, EnvList, getPersonaList, createPersona, updatePersona, deletePersona }) => {

    const [newName, setNewName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEnvId, setNewEnvId] = useState('');
    const [selectedProject, setSelectedProject] = useState('select');
    const [PersonaList, setPersonaList] = useState([]);
    const [EnvData, setEnv] = useState([]);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        getPersonaList()
    }, [])

    useEffect(() => {
        if (EnvList) {
            setEnv(EnvList); 
            setNewEnvId(EnvList.env_id) 
        }
    }, [EnvList]);

    console.log("List",EnvList)

    const check_Connection=(row)=>{
        console.log("Row in Persona", row)
        // showConfirmationAlert('Please Wait While We Check Your Credentials', 'This might take a few moments.',false)
        // .then((result) => {
        // if (result.isConfirmed) {
        // console.log("User Confirmed")
        // } 
        // });
        
        toast.warning('Please Wait While We Check Your Credentials This might take a few moments')

        const obj={
            Base_url:EnvData.url,
            Username:row.userName,
            Password:row.password
        }
        EnviromentService.CheckEnviroment(obj).then((res)=>{
            console.log("Verify Credentials ", res)
            if(res.data.message === "True"){
                toast.success("Conection Established Sucessfully ")
            }else{
                toast.error("Conection Failed ")
            }
            
        }).catch((err)=>{
            console.log("error",err)
        })

        
    }

    useEffect(() => {
        const filteredData = ListData?.filter(item => item.env_id === newEnvId);
        if (filteredData && filteredData?.length > 0) {
            let initialRows = [];
            filteredData?.map((attribute, i) => {
                const obj = {
                    persona_id: attribute.persona_id,
                    index: i + 1,
                    name: attribute.name,
                    userName: attribute.username,
                    password: attribute.password,
                    env_id: attribute.env_id

                }
                initialRows.push(obj);
                setPersonaList(initialRows);
            })
        }
    }, [newEnvId,ListData])


    const handleAddRow = () => {
        const newRow = {
            id: PersonaList?.length + 1,
            name: newName,
            userName: newUserName,
            password: newPassword,
            env_id: newEnvId

        };

        setPersonaList([...PersonaList, newRow]);
        setNewName('');
        setNewUserName('');
        setNewPassword('')
        setError('');
    };

    const deleteAttributeById = (id, index) => {
        if (!PersonaList[index].persona_id) {
            const updatedAttributeList = [...PersonaList];
            updatedAttributeList.splice(index, 1);
            setPersonaList(updatedAttributeList);
        }
        else {
            deletePersona(id)
                .then(() => {
                    // getAttributes(data.step_id);
                    const updatedAttributeList = [...PersonaList];
                    updatedAttributeList.splice(index, 1); // Remove the item at the given index
                    getPersonaList()
                    setPersonaList(updatedAttributeList);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }

    }

    const handleNameChange = (event, index) => {
        const updatedAttributeList = [...PersonaList];
        updatedAttributeList[index].name = event.target.value;
        setPersonaList(updatedAttributeList);
    }

    const handleUserNameChange = (event, index) => {
        const updatedAttributeList = [...PersonaList];
        updatedAttributeList[index].userName = event.target.value;
        setPersonaList(updatedAttributeList);
    }
    const handlePasswordChange = (event, index) => {
        const updatedAttributeList = [...PersonaList];
        updatedAttributeList[index].password = event.target.value;
        setPersonaList(updatedAttributeList);
    }

    const handleSelectChange = (event, index) => {
        const updatedAttributeList = [...PersonaList];
        updatedAttributeList[index].env_id = event;
        setPersonaList(updatedAttributeList);
    };


    const onSubmit = (index) => {
        if (!PersonaList[index]?.persona_id) {
            const obj = {
                name: PersonaList[index]?.name,
                userName: PersonaList[index]?.userName,
                password: PersonaList[index]?.password,
                env_id: newEnvId,
            };
            console.log(obj)
            createPersona(obj).then((res) => {
                console.log(res)
                PersonaList[index] = res.data;
                console.log("attributes", res.data)
                setPersonaList([...PersonaList]);
                toast.success(res.data?.name + " Persona created successfully");
            })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
        if (PersonaList[index]?.persona_id) {
            PersonaList?.map(data => data.persona_id === PersonaList[index]?.persona_id)
            const object = {
                id: PersonaList[index].persona_id,
                name: PersonaList[index]?.name,
                userName: PersonaList[index]?.userName,
                password: PersonaList[index]?.password,
                env_id: newEnvId,

            };
            console.log("update", object)
            updatePersona(object).then((res) => {
                toast.success(object?.name + " Persona updated successfully");
            })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }



    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3 className='label-bold'>{EnvData?.proj_name} - {EnvData?.name}</h3>
                <Button onClick={handleAddRow} startIcon={<AddIcon />} style={{ marginLeft: 'auto' }}>
                    Add Persona
                </Button>
            </div>
            <Divider></Divider>
            <div className='marginTop'>
                <TableContainer component={Card} className='dashboard-table table td'>
                    <div className="scroll">
                        <Table>
                            <TableHead className='th'>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>UserName</TableCell>
                                    <TableCell>Password</TableCell>
                                    {/* <TableCell>Environment</TableCell> */}
                                    <TableCell>Action</TableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody >
                                {PersonaList?.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                placeholder="Enter Name"
                                                onChange={(event) => handleNameChange(event, index)}
                                                type="text"
                                                value={row.name}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                }}
                                            /></TableCell>

                                        <TableCell>
                                            <TextField
                                                placeholder="Enter UserName"
                                                onChange={(event) => handleUserNameChange(event, index)}
                                                type="text"
                                                value={row.userName}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                placeholder="Enter Password"
                                                required
                                                onChange={(event) => handlePasswordChange(event, index)}
                                                value={row.password}
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: row.password && (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {/* <TextField
                                           
                                                onChange={(event) => handlePasswordChange(event, index)}
                                                type="text"
                                                value={row.password}
                                                menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    }}
                                            /> */}
                                        </TableCell>
                                        {/* <TableCell>
                                            <TextField

                                                onChange={(event) => handleSelectChange(event, index)}
                                                type="text"
                                                value={EnvData.value === row.env_id?EnvData.label:null }
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                }}
                                                />
                                            <div >
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={EnvData}  // EnvData should contain { label, value }
                                                    name={row.env_id}
                                                    value={EnvData.find(option => option.value === row.env_id)}  // Match based on value (id) from EnvData
                                                    onChange={(event) => handleSelectChange(event, index)}  // Handle selection change
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </div>
                                        </TableCell> */}
                                        <TableCell>

                                            <Tooltip title="Save">
                                                <SaveIcon style={{ color: '#34B1AA' }} onClick={() => onSubmit(index)} className="action-icon" />
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteAttributeById(row.persona_id, index)} className="action-icon clickIconClassDelete" />
                                            </Tooltip>
                                            <Tooltip title="Verify Credentials"><PlayCircleOutlineIcon className="action-icon" onClick={() => check_Connection(row)} titleAccess='Verify Credentials' /></Tooltip>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </div>
                </TableContainer>
            </div>
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    ListData: state.Enviroment_Persona?.PersonaList?.rows,


});

const mapDispatchToProps = {
    getPersonaList,
    createPersona,
    updatePersona,
    deletePersona,
 
};

export default connect(mapStateToProps, mapDispatchToProps)(Persona);
