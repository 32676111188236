import { Card, CardContent, Button, Typography ,Grid , TextField , Tooltip} from '@mui/material';
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createTestCase, createTestSteps, getTestStepsList, deleteTestStep, updateTestCase, getTestcasesById } from "../redux/actions/testcase";
import { getAllAttributeListForExport, getAllValidationListForExport, getAllTestStepListForExport } from "../redux/actions/exportImport";
import {getProjects} from "../redux/actions/auth";
import MenuItem from '@mui/material/MenuItem';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import Validations from './Validation';
import Checkbox from '@mui/material/Checkbox';
import '../styles/project.css';
import ExportExcel from './Excel/ExcelDownload';
import TestCasesTable from './TestCasesTable';
import Uploader from './Uploader';
import '../styles/custom.css';
import '../styles/common.css';
import Divider from "@mui/material/Divider";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import validationRules from '../components/ValidationRules';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import validateForm from '../components/Validate';
import ErrorBox from '../components/ErrorBox';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingButton = styled(Button)(({ theme }) => ({
  animation: `${blink} 2s infinite`,
}));


export const Testcase = ({ listOfProject, getTestStepsList, updateTestCase, testCaseData, getTestcasesById, getAllAttributeListForExport, getProjects,
    getAllValidationListForExport, getAllTestStepListForExport }) => {
    const { state } = useLocation();
    const [testCaseName, setTestCaseName] = useState('');
    const [openValidation, setOpenValidation] = React.useState(false);
    const [openUploader, setOpenUploader] = React.useState(false);
    const [isExportExcel, setExportExcel] = React.useState(false);
    const [testStepData, setTestStepData] = React.useState(false);
    const [selectedProject, setSelectedProject] = useState('');
    const [isTemplate, setIsTemplate] = useState(false);
    const [type, setType] = useState('');
    const [data, setData] = React.useState([]);
    
      let initialValues = {
        name:'' 
      };
   
      const fields = [
        { name:'name',  label: 'Test Case', type: 'text', validations: [validationRules.required] },
      ];
    
      const [values, setValues] = useState(initialValues);
      const [errors, setErrors] = useState();
      const [globalError, setGlobalError] = useState('');

    useEffect(() => {
        if (state) {
            getTestcasesById(state?.case_id);
            getProjects(0,0,null,null, JSON.stringify({}));
        }
    }, [state]
    )
    
    useEffect(() => {
        if (testCaseData && testCaseData.case_id) {
            setTestCaseName(testCaseData.case_name);
            setIsTemplate(testCaseData.case_is_template);
            getTestStepsList(testCaseData.case_id);
            setSelectedProject(testCaseData?.proj_id);
            initialValues.name=testCaseData.case_name;
            setValues(initialValues);
        }
    }, [testCaseData]
    )
      
      

    const handleSelectChange = (event) => {
        setSelectedProject(event.target.value);
    };
    const navigate = useNavigate();

    const handleCloseValidation = () => {
        setOpenValidation(false);
        setOpenUploader(false);
    };

    const handleCheckboxChange = (event) => {
        setIsTemplate(event.target.checked);
    }

    // const onChangeTestCaseName = (event) => {
    //     setTestCaseName(event.target.value);
    // }
    const onChangeTestcaseName = (event) => {
       
        const {name, value} = event.target;
        setValues({...values, [name]:value});
        //setTestCaseName(initialValues.name);
        
      }
      const validate = () => {
        const { newErrors, globalError } = validateForm(values, fields);
        setErrors(newErrors);
        setGlobalError(globalError);
        return Object.keys(newErrors).length === 0;
      };

    const UploaderOpen = (type) => {
        setType(type);
        setOpenUploader(true);
        setExportExcel(false);
    }

    const openUploaderFun = (type) => {
        setType(type);
        fetchData(type);        
    }
    const onUpdateTestCase = () => {
        if (validate()) {
        const updatedTestCase = {
            id: testCaseData?.case_id,
            name: values[fields[0].name],
            case_is_template: isTemplate,
            proj_id: selectedProject,
            tags: "tags"
        }
        setValues({...values, [fields[0].name]:updatedTestCase.name});
        setTestCaseName(updatedTestCase.name);
        updateTestCase(updatedTestCase);
    }
    }

    const navigateToAddTemplate = (testCaseId) => {
        navigate(`/add-template/${testCaseId}`, { state: testCaseData })
    }

    const fetchData = (type) => {
        setType(type);
        let id = testCaseData.case_id ? testCaseData.case_id : testCaseData.test_id;
        if (type === 'attribute') {
            getAllAttributeListForExport(id).then((res) => {
                setData(res);
                setExportExcel(true);
              })
              .catch((error) => {
                  console.error('Error:', error);
              });
        }
        if (type === 'validation') {
            getAllValidationListForExport(id).then((res) => {
                setData(res);
                setExportExcel(true);
              })
              .catch((error) => {
                  console.error('Error:', error);
              });
        }
        if (type === 'testStep') {
            getAllTestStepListForExport(id).then((res) => {
                setData(res);
                setExportExcel(true);
              })
              .catch((error) => {
                  console.error('Error:', error);
              });
        }
    }

    const navigateToRunJob = (row) => {
        let obj = {
            case_id: row
        }
        navigate(`/runJob`, { state: testCaseData });
    }
    
    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
          return text;
        }
        return text?.substring(0, maxLength) + '...';
    };

    return (
        <div>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
               <Tooltip title={testCaseName}>{ testCaseName && truncateText(testCaseName, 60)}</Tooltip> 
                    {/* <BlinkingButton
                        disabled={testCaseData?.teststep_counter === 0}
                        colorStyle = 'primary'
                        className='right'
                        onClick={() => navigateToRunJob(testCaseData.case_id)}
                    >
                       <PlayCircleOutlineIcon/> Run Test Case
                    </BlinkingButton> */}
                   <Button disabled={testCaseData?.teststep_counter == 0} className='right' onClick={() => navigateToRunJob(testCaseData)}><PlayCircleIcon />Run Test case</Button>
                </Typography>
                <Divider></Divider>
                <CardContent>

                <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Testcase name:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                                {fields.map((field) => (
                                     <TextField 
                                     name={field.name} value={values[field.name]} onChange={onChangeTestcaseName} />
                            ))}
                        </Grid>

                        <Grid item xs={4} sm={6}>
                           <Checkbox
                                checked={isTemplate}
                                value={isTemplate}
                                onChange={handleCheckboxChange}
                            ></Checkbox> Is Template
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Project Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={6}>
                        <Select onChange={handleSelectChange} value={selectedProject} style={{ width: '66%', height: '40px' }}>
                                {listOfProject?.map((option, index) => (
                                    <MenuItem value={option?.proj_id} key={index}>{option?.proj_name}</MenuItem>
                                ))}
                            </Select>
                            {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
                        </Grid>

                    </Grid>

                    {/* <Row>
                            {globalError && <ErrorBox message={globalError} onClose={() => setGlobalError('')} />}
                            {fields.map((field) => (

                                <Col className='col-md-4'>
                                <label className='label-bold marginRight'>Testcase name:</label>
                                <span>
                                    <input style={{ border: '1px solid #ced4da', borderRadius: '5px', width: '100%', height: '40px' }} name={field.name} value={values[field.name]}  type={field.type} onChange={onChangeTestcaseName}/>
                                </span>
                                </Col>
                           
                            ))}

                        <Col className='col-md-4'>
                            <label className='label-bold marginRight'>Project:</label>
                            <Select onChange={handleSelectChange} value={selectedProject} style={{ width: '70%', height: '40px' }}>
                                {listOfProject?.map((option, index) => (
                                    <MenuItem value={option?.proj_id} key={index}>{option?.proj_name}</MenuItem>
                                ))}
                            </Select>
                        </Col>
                        <Col className='col-md-4'>
                            <Checkbox
                                checked={isTemplate}
                                value={isTemplate}
                                onChange={handleCheckboxChange}
                            ></Checkbox> Is Template
                        </Col>
                        <Col>
                            <span className='right'>
                                <Button onClick={onUpdateTestCase}>Update</Button>
                                <Button colorStyle='cancel' onClick={() => navigate('/testcases/all')}>Back</Button>
                            </span>
                        </Col>
                    </Row> */}
                    <br></br>
                    <div>
                        <Button colorStyle='primary' onClick={() => UploaderOpen('testStep')}>Step Upload</Button>
                        <Button colorStyle='primary' onClick={() => UploaderOpen('attribute')}>Attribute Upload</Button>
                        <Button colorStyle='primary' onClick={() => UploaderOpen('validation')}>Validation Upload</Button>
                        <Button onClick={() => openUploaderFun('testStep')}>Export Test Step</Button>
                        <Button onClick={() => openUploaderFun('attribute')}>Export Attributes</Button>
                        <Button onClick={() => openUploaderFun('validation')}>Export Validations</Button>
                        <Button onClick={() => navigateToAddTemplate(testCaseData.case_id)}>Add Templates</Button>
                    </div>

                    <div className='marginTop'>
                        <Button className='right' colorStyle='cancel' onClick={() => navigate('/testcases/all')}>Back</Button>
                        <Button className='right' onClick={onUpdateTestCase}>Update</Button>
                    </div>
                    <br></br>
                </CardContent>
            </Card>
            <TestCasesTable></TestCasesTable>
            <Modal
                open={openValidation}
                onClose={handleCloseValidation}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className='modalContainer'
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: 400, // Adjust width as needed
                        maxWidth: '90%',
                        maxHeight: '90%',
                        overflow: 'auto',
                        borderRadius: 4,
                    }}
                >
                    {/* Header */}

                    <div className="modal-header">
                        <h3 className='label-bold'>
                            Modal Header
                        </h3>
                    </div>
                    <Typography variant="h5" id="child-modal-title" gutterBottom>
                        Modal Header
                    </Typography>
                    {/* Content */}
                    <Box component="form" noValidate autoComplete="off">
                        <Validations data={testStepData} />
                    </Box>
                    {/* Footer */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleCloseValidation} color="primary">
                            Close
                        </Button>
                        {/* Additional buttons or actions can be added here */}
                    </Box>
                </Box>
            </Modal>
                    
               <Uploader data={testStepData} testData={testCaseData} type={type} openModal={openUploader} handleCloseModal={handleCloseValidation}/>

            {/* <Modal
                open={openUploader}
                onClose={handleCloseValidation}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className='modalContainer'>
                <Box component="form"
                    sx={{
                        ...modalPopUpStyle, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <Uploader data={testStepData} testData={testCaseData} type={type}></Uploader>
                </Box>
            </Modal> */}
            {isExportExcel && <ExportExcel testData={testCaseData} type={type} data={data}></ExportExcel>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    testStepsList: state?.auth?.testStepList,
    testCaseData: state?.auth?.testCaseObject,
    listOfProject: state?.auth?.listProject?.rows
});

const mapDispatchToProps = {
    createTestCase,
    createTestSteps,
    getTestStepsList,
    deleteTestStep,
    updateTestCase,
    getTestcasesById,
    getProjects,
    getAllAttributeListForExport,
    getAllValidationListForExport,
    getAllTestStepListForExport
};

export default connect(mapStateToProps, mapDispatchToProps)(Testcase);