import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class runJobService {
    constructor() {
        this.headers = createHeaders();
    }

    getJobRunningStatus = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/runjob`;
           const response = await axios.post(url, obj
            , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    runSuites = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/runsuite`;
           const response = await axios.post(url,obj
               , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    stopJob = async (taskId,type) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/stopJob`;
           const response = await axios.post(url, {
                taskId: taskId,
                type:type,
            }, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}

export default new runJobService();