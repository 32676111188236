import { CREATE_JIRA_TICKET,GET_JIRA_LIST } from "./types";
import JiraService from "../../services/jira.service";
import { toast } from 'react-toastify';
import jiraService from "../../services/jira.service";

export const getticketList = (page, pageSize, sortColumn, sortDirection,filters) => async (dispatch) => {
    try {
        let res = await jiraService.getticketList(page, pageSize, sortColumn, sortDirection,filters);
        if (res.status === 200) {
            dispatch({
                type: GET_JIRA_LIST,
                payload: res.data
            });
        } else {
            toast.error("Unable to fetch clock task list : " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


export const createjiraticket = (object) => async (dispatch) => {
    try {
        console.log('object',object);
        let res = await JiraService.createjiraticket(object);
        if (res.status === 200) {
            dispatch({
                type: CREATE_JIRA_TICKET,
                payload: res.data
            });
            toast.success("Jira Ticket "+res.data.key+" created successfully");
            return res
        } else {
            toast.error("Unable to create Jira Ticket " + res.status);
        }
    } catch (error) {
        toast.error(error);
    }
};


