import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Button, FormControl, FormHelperText, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip, Typography, Box, IconButton, Divider
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Modal from '@mui/material/Modal';
import { modalPopUpStyleLarge } from '../../styles/getLPTheme';
import { toast } from 'react-toastify';
import { getTestDetailedReport, getTestJobs, getSuiteTestJobs } from "../../redux/actions/testCaseReport";
import { getTestCaseForDropdown } from "../../redux/actions/testcase";
import TestJobService from '../../services/testJob.service'
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StopIcon from '@mui/icons-material/Stop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import DateFormatter from '../../components/DateFormatter';
import Select from 'react-select';
import '../../styles/common.css';
import '../../styles/custom.css';
import showConfirmationAlert from "../../components/Confirm";
import DeleteIcon from '@mui/icons-material/Delete';




function DetailTestcaseReport({ getTestCaseForDropdown, getTestDetailedReport, getTestJobs, getSuiteTestJobs, listOfJob, suiteJobList }) {
    const [testcaseList, setTestcaseList] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState('');
    const [openRows, setOpenRows] = useState({});
    const params = useParams();
    const { state } = useLocation();
    const [suiteId, setSuiteId] = useState('');
    const [suiteName, setSuiteName] = useState('');
    const [testCase, setTestCase] = useState([]);
    const [video, setVideo] = useState('')
    const [open, setOpen] = useState(false);
    const [open_video, setOpenVideo] = React.useState(false);
    const [image, setImage] = useState('');
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [newdata,setNewData] = useState('');
    const navigate = useNavigate();
    const Auth = JSON.parse(localStorage.getItem("authentication"));
    const handleClose = () => {
        setOpen(false);
    };
    const navigateToJira = (row,row2) => {
        const obj = {
            message:row.message,
            stepName:row.step_name,
            TestcaseName:row2.test_case_name,
            video:row2.video_url,
            image:row.screenshot,
        }
       
        navigate('/Jira', { state: obj });
      }

    useEffect(() => {
        getTestCaseForDropdown(0, 0, null, null, JSON.stringify({}),'All',Auth["proj_id"]).then((res) => {
            createDropDownList(res.rows);
        });
    }, [getTestCaseForDropdown,Auth["proj_id"]]);

    useEffect(() => {
        if (state) {
            setSuiteId(state?.suite_id);
            setSuiteName(state?.suite_name);
            getSuiteTestJobs(state.id);
        } else {
            getTestJobs(Auth["proj_id"]);
        }
    }, [Auth["proj_id"]]);

    useEffect(() => {
        if (params.sys_name === 'TestCaseReport') {
            setSuiteId(null);
            setSuiteName(null);
            setTestCase(listOfJob);
        } else {
            setTestCase(suiteJobList);
        }
    }, [params.sys_name, listOfJob, suiteJobList]);

    const createDropDownList = (allTestcaseList) => {
        let testcasesArray = [];
        if (allTestcaseList) {
            allTestcaseList.forEach((file) => {
                let obj = {
                    id: file.case_id,
                    value: file.case_name,
                    label: file.case_name
                };
                testcasesArray.push(obj);
            });
        }
        setTestcaseList(testcasesArray);
    };

    const updatePopUp = (image) => {
        setOpen(true);
        setImage(image);
    };
    
  const updatePopUpVideo = (video) => {
    setOpenVideo(true);
    setVideo(video)
  }
  const handleVideoClose = () => {
    setOpenVideo(false);
  };

    const toggleRow = (id) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (fromDate?.isAfter(toDate)) {
            toast.error('From Date cannot be greater than To Date.');
            return;
        }
        if ((fromDate && !toDate) || (!fromDate && toDate)) {
            toast.error('Both date ranges are mandatory');
            return;
        } else {
            setError('');
            
            let obj = {
                from: fromDate ? fromDate.format('YYYY-MM-DD') : null,
                to: toDate ? toDate.format('YYYY-MM-DD') : null,
                testid: selectedOption ? selectedOption?.id : null,
                proj_id:Auth["proj_id"]
            };
            setNewData(obj)
            getTestDetailedReport(obj).then(res => {
                setTestCase(res);
                const message = res && res?.length > 0 ? "Record Found":"No record Found"
                toast.success(message)
            });
        }
    };

    const deleteJob = (row) => {
        console.log(row);
        showConfirmationAlert('Are you sure you want to Delete ?')
        .then((result) => {
        if (result.isConfirmed) {
          TestJobService.deleteTestJobList(row.id).then((res) => {
            toast.success(res.data.message)
            getTestDetailedReport(newdata).then(res => {
                setTestCase(res);
            });
          }).catch((error) => {
            console.error('Error:', error);
            });
        }
      });
      }

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        setSelectedOption(null);
        setTestCase([]);
    };

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.substring(0, maxLength) + '...';
    };

    const renderChildTable = (parentRowId) => {
        const parentRecord = testCase?.find((row) => row.id === parentRowId);
        if (openRows[parentRowId] && parentRecord) {
            const childRecords = parentRecord.sellogs;
            return (
                <Table>
                    <TableHead>
                        <TableRow className='th'>
                            <TableCell>Screenshot</TableCell>
                            <TableCell>Step Name</TableCell>
                            <TableCell>Log Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {childRecords.map((record) => (
                            <TableRow key={record.id}>
                                <TableCell>
                                    {record.screenshot && <Link onClick={() => updatePopUp(record)}><img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${record.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '100px' }} /></Link>}
                                </TableCell>
                                <Tooltip title={record.step_name}>
                                    <TableCell>{truncateText(record.step_name, 20)}</TableCell>
                                </Tooltip>
                                <TableCell>{record.log_type}</TableCell>
                                <TableCell>
                                    {record.status === 'running' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}</div>) :
                                        record.status === 'stop' ? (<div className="badge badge-opacity-warning text-capitalize">{record.status}<StopIcon style={{ color: '#FF0000' }} /></div>) :
                                            record.status === 'error' ? (<div className="badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                                                record.status === 'failure' ? (<div className="badge badge-danger text-capitalize">{record.status}<ErrorIcon style={{ color: '#B22222' }} /></div>) :
                                                    (<div className="badge badge-opacity-success text-capitalize">{record.status}<CheckCircleIcon style={{ color: '#008000' }} /></div>)}
                                </TableCell>
                                <TableCell className="text-break" style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>{record.message}</TableCell>
                                <TableCell > 
                {record.status === 'error' || record.status === 'failure' ?
                  <Button onClick={() => (navigateToJira(record,parentRecord))} colorStyle="cancel"> Create Ticket</Button>:''}
                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        }
        return null;
    };

    const tableRows = testCase?.length > 0 ? testCase.map((row) => (
        <React.Fragment key={row.id}>
            <TableRow>
                <Tooltip title={row.test_case_name}>
                    <TableCell>{truncateText(row.test_case_name, 30)}</TableCell>
                </Tooltip>
                <TableCell>{row.total_steps}</TableCell>
                <TableCell><DateFormatter date={row.execution_time} /></TableCell>
                <TableCell><DateFormatter date={row.completion_time} /></TableCell>
                <TableCell>
                    {row.status === 'running' ? (
                        <div className="badge badge-opacity-warning text-capitalize">{row.status}</div>
                    ) : row.status === 'stopped' ? (
                        <div className="badge badge-opacity-warning text-capitalize">
                            {row.status}
                            <StopIcon style={{ color: '#FF0000' }} />
                        </div>
                    ) : row.status === 'error' ? (
                        <div className="badge badge-danger text-capitalize">
                            {row.status}
                            <ErrorIcon style={{ color: '#B22222' }} />
                        </div>
                    ) : row.status === 'failure' ? (
                        <div className="badge badge-danger text-capitalize">
                            {row.status}
                            <ErrorIcon style={{ color: '#B22222' }} />
                        </div>
                    ) : (
                        <div className="badge badge-opacity-success text-capitalize">
                            {row.status}
                            <CheckCircleIcon style={{ color: '#008000' }} />
                        </div>
                    )}
                </TableCell>
                
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRow(row.id)}
                    >
                        {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {!suiteId && row.status !== 'running' && (
                <TableCell>
                    {row.video_url && (
                    <Link onClick={() => updatePopUpVideo(row)} title="Play">
                        <SmartDisplayIcon style={{ color: 'red', cursor: 'pointer' }}></SmartDisplayIcon>
                    </Link>
                    )}
                </TableCell>
                
          
          

        )}
        {row.status !== 'running' &&(
            <TableCell>
            <Tooltip title="Delete">
                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteJob(row)} className="action-icon clickIconClassDelete" />
            </Tooltip>
            </TableCell>
            )}
            </TableRow>
            
            <TableRow>
                <TableCell colSpan={6}>
                    {renderChildTable(row.id)}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )) : (
        <TableRow>
            <TableCell colSpan={6} align="center">
                <span className='marginTop text-color-grey'>No test cases available.</span>
            </TableCell>
        </TableRow>
    );

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                    <Card raised sx={{ minWidth: "100%", overflowX: "auto" }}>
                        <Typography variant="h5" sx={{ p: 2 }}>
                            Detailed Testcase Report
                        </Typography>
                        <Divider />
                        <CardContent>
                            <form onSubmit={handleSubmit} className='flex-container'>
                                <div className='flex-item'>
                                    <FormControl fullWidth>
                                        <label className='label-bold'>Testcase List</label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            name="name"
                                            options={testcaseList}
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                container: base => ({ ...base, width: '100%' }),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className='flex-item'>
                                    <label className='label-bold'>From</label>
                                    <DatePicker
                                        error={Boolean(error)}
                                        value={fromDate}
                                        onChange={(newValue) => setFromDate(newValue)}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {error && <FormHelperText>{error}</FormHelperText>}
                                </div>
                                <div className='flex-item'>
                                    <label className='label-bold'>To</label>
                                    <DatePicker
                                        error={Boolean(error)}
                                        value={toDate}
                                        onChange={(newValue) => setToDate(newValue)}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {error && <FormHelperText>{error}</FormHelperText>}
                                </div>
                                <div className='flex-item marginTop'>
                                    <Button type="submit">Submit</Button>
                                </div>
                                <div className='flex-item marginTop'>
                                    <Button onClick={handleReset} color="secondary">Reset</Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </LocalizationProvider>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="modalContainer">
                <Box
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="modal-header">
                        <img src={`${process.env.REACT_APP_API_URL}/api/v1/getScreenshot/${image.id}?token=${token}`} alt="Screenshot" style={{ maxWidth: '90%', height: 'auto' }} />
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
        open={open_video}
        onClose={handleVideoClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="modalContainer">
        <Box
          sx={{
            ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off">

          <div className="modal-header">
            <video controls style={{ maxWidth: '100%' }}>
              <source src={`${process.env.REACT_APP_API_URL}/api/v1/getVideo/${video.id}?token=${token}`} type="video/mp4" />
            </video>
          </div>

          <div className="modal-footer">
            <Button onClick={handleVideoClose} className="modalButton modalCloseButton">Close</Button>
          </div>
        </Box>
      </Modal>
            <CardContent className='marginTop'>
                <TableContainer component={Card}>
                    <Table className='dashboard-table TableCell table-responsive'>
                        <TableHead>
                            <TableRow className='th'>
                                <TableCell>Test Case Name</TableCell>
                                <TableCell>Total Steps</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>Completion Time</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Details</TableCell>
                                {!suiteId && <TableCell>Video</TableCell>}
                                <TableCell >Action</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </div>
    );
}

const mapStateToProps = (state) => ({
    suiteJobList: state.testCaseReport?.suiteTestCaseList,
    listOfJob: state.testCaseReport?.testJobList,
});

const mapDispatchToProps = {
    getTestCaseForDropdown,
    getTestDetailedReport,
    getTestJobs,
    getSuiteTestJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTestcaseReport);
