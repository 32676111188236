import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class jiraService {
    constructor() {
        this.headers = createHeaders();
     }

    createjiraticket = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/jira`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getticketList(page,pageSize,sortColumn, sortDirection,filters) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/jira?pageNo=${page}&size=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&filters=${filters}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    uploadattachment = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/jira/upload`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new jiraService();