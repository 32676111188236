import React from 'react';
import { Card, CardContent, Button, TextField, Divider, Typography, Grid,Checkbox,Tooltip,InputAdornment,IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../styles/project.css'
import { useLocation } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getJiraList, createjiratoken ,deleteJira,updateJira} from '../redux/actions/jiraToken';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";



const JiraAccount = ({
    createjiratoken,
    getJiraList,count,
    jiraTokenList,
    deleteJira,
    updateJira
}) => {
    
    const [key, setkey] = useState(null);
    const [UserName, setUserName] = useState(null);
    const [Token, setToken] = useState(null);
    const [IsDefault, setIsDefault] = useState(false);
    const [Base_url, setBaseUrl] = useState(null);
    const [JiraTokenList, setJiraTokenList] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [id, setId] = useState(null);
    const [error, setError] = useState('');
    const [urlerror, setUrlError] = useState('');
    const [showEditButton, setShowEditButton] = useState(false);
    const [isDisbaled, setisDisbaled] = useState(false);
    const [showPassword, setShowPassword] = useState(false);



  
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    const columns = [
        { field: "projectkey", headerName: "Key" },
        { field: "username", headerName: "UserName" },
        { field: "token", headerName: "Token",renderCell: (params) => (
            <span>{'•'.repeat(params.value.length)}</span> // Mask the token value
          ), },
        { field: "base_url", headerName: "Base URL" },
        { field: "isdefault", headerName: "Default" },
        { field: "u_name", headerName: "Created By" },
        {
            field: "aciton",
            headerName: "Actions",
            renderCell: (params) => (
              <>
             <Tooltip title="Edit"><EditIcon className="action-icon" onClick={() => UpdateJiraToken(params.row)} titleAccess='Edit'/></Tooltip> 
             <Tooltip title="Delete"><DeleteIcon className="action-icon-delete" onClick={() => deleteJiraToken(params.row)}/></Tooltip>
              
              </>
            ),
          },

    ];
    const [width, setWidth] = useState(100 / columns.length + '% !important');



    const deleteJiraToken = (row) => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        deleteJira(row.id)
          .then(() => {
            getJiraList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
        })
          .catch((error) => {
            console.error('Error:', error);
          });
      }

    useEffect(() => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        getJiraList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
    }, [getJiraList, paginationModals.page, paginationModals.pageSize]
    )

    useEffect(() => {
        const dataWithIds = jiraTokenList?.map((row, index) => ({
            ...row,
            id: row.token_id,
        }));
        setJiraTokenList(dataWithIds)

        setTotalCount(count);
    }, [jiraTokenList]
    )

    const onChangekey = (event) => {
        setkey(event.target.value);
    }

    const onChangeUserName = (event) => {
        setUserName(event.target.value);
        if (!isValidEmail(event.target.value)) {
            setError('Invalid email format');
          } else {
            setError('');
          }
    }
    
    const onChangeToken = (event) => {
        setToken(event.target.value);
    }

    const onChangeIsDefault = (event) => {
        setIsDefault(event.target.checked);
    }

   
    const onChangeBaseUrl = (event) => {
        setBaseUrl(event.target.value);
        if (!isValidURL(event.target.value)) {
            setUrlError('Invalid URL format');
        } else {
            setUrlError('');
        }
    };

    const UpdateJiraToken = (row) => {
        setisDisbaled(false)
        setShowEditButton(true)
        if(row.isdefault){
            setisDisbaled(true)
        }
        setId(row.id)
        setkey(row.projectkey);
        setUserName(row.username)
        setIsDefault(row.isdefault)
        setToken(row.token)
        setBaseUrl(row.base_url)

        
       
    }
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };


    const isValidURL = (url) => {
        const regex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/i;
        return regex.test(url);
    };

    const Cancel=()=>{
        setShowEditButton(false)
        setId('')
        setkey('')
        setUserName('')
        setIsDefault('')
        setToken('')
        setBaseUrl('')
        setisDisbaled(false)
    } 

    const Update = () => {
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        
        // Define the object with the necessary parameters
        const obj = {
            token_id: id,
            key: key,
            UserName: UserName,
            IsDefault: IsDefault,
            Token: Token,
            Base_url: Base_url
        };
    
        // Call the update function and then refresh the list on success
        updateJira(obj)
            .then(() => {
                getJiraList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
                setkey('');
                setToken('');
                setIsDefault(false);
                setUserName('');
                setBaseUrl('');
                setId('');
                setShowEditButton(false)
                setisDisbaled(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    

    const onSubmit = (event) => {
        event.preventDefault();
        if (key === '') {
            toast.error('Test Project Key can not be empty');
            return;
        }
        else if (UserName === '') {
            toast.error('Email can not be empty');
            return;
        }
        else if (Base_url === '') {
            toast.error('URL can not be empty');
            return;
        }
        else if (Token === '') {
            toast.error('Token can not be empty');
            return;
        }else if (error || urlerror ){
            if(error){
                toast.error(error);
            }
            if(urlerror){
                toast.error(urlerror);
            }
                
        }
        else {
            const obj = {
                key: key,
                UserName: UserName,
                IsDefault: IsDefault,
                Token: Token,
                Base_url:Base_url
            }
          
            createjiratoken(obj)
                .then((res) => {
                    const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
                    getJiraList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
                    setkey('');
                    setToken('');
                    setIsDefault(false);
                    setUserName('');
                    setBaseUrl('');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }
    return (
        <>
        <div>
             <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Add Jira API Token
                </Typography>
                <Divider></Divider>
                <CardContent>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Jira Project Key:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField placeholder="Enter Project Key" required onChange={onChangekey} value={key}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Email:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <TextField placeholder="Enter Email" onChange={onChangeUserName} value={UserName}/>
                            </Typography>
                            {error && <span style={{ color: 'red' }}>{error}</span>}
                        </Grid>
                        {/* <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Token:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField placeholder="Enter Token"  type="password" required onChange={onChangeToken} value={Token}/>
                        </Grid> */}
                        <Grid item xs={4} sm={2}>
                <Typography variant="subtitle2" fontSize={14}>Token:</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
                <TextField
                    placeholder="Enter Token"
                    required
                    onChange={onChangeToken}
                    value={Token}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:Token && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Base Url:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField placeholder="Enter Url" required onChange={onChangeBaseUrl} value={Base_url}/>
                            {urlerror && <span style={{ color: 'red' }}>{urlerror}</span>}

                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Is Default:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <Checkbox className="form-check-input" checked={IsDefault} value={IsDefault}
                                onChange={onChangeIsDefault} disabled={showEditButton && isDisbaled}/>
                                                
                        </Grid>
                        
                    </Grid>

                    <div style={{ marginBottom: '30px' }}>
                    {showEditButton ? (
                        <>
                        <Button sx={{ float: 'right' }} colorStyle={'cancel'} onClick={Cancel}>
                          Back
                        </Button>
                        <Button sx={{ float: 'right' }} onClick={Update}>
                          Update Jira Token
                        </Button>
                        
                      </>
                        ) : (
                        <Button sx={{ float: 'right' }} onClick={onSubmit}>
                           + Add Jira Token
                        </Button>
                        )}
                    </div>
                </CardContent>

            </Card> 
            <br></br>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Jira API Tokens List
                </Typography>
                <Divider></Divider>

                <CardContent>
                { JiraTokenList &&
                    <ServerPaginationGridNoRowCount
                        setPaginationModals={setPaginationModals}
                        paginationModals={paginationModals}
                        rowsData={JiraTokenList.length > 0 ? JiraTokenList : []}  
                        total={count}
                        columns={columns}
                        columnWidth={width}
                        setSortModels={setSortModels}
                        sortModels={sortModels}
                        setFilterModels={setFilterModels}
                        filterModels={filterModels}
                    />}
                </CardContent>

            </Card>
        </div>
        <div>
            
          
        </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    jiraTokenList: state.jiraToken?.JiraTokenList?.rows,
    count: state.jiraToken?.JiraTokenList?.count,
    
});

const mapDispatchToProps = {
    getJiraList, createjiratoken,deleteJira,updateJira
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraAccount);


