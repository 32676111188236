import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import testImage from '../Assets/GirikonLogo.jpg'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { login, loginsso } from "../redux/actions/auth";
import { connect } from "react-redux";
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://girikon.ai/">
        Girikon AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const styles = {
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#757575',
    border: '1px solid #dcdcdc',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#f7f7f7',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  googleicon: {
    marginRight: '10px',
    color: '#EA4335'
  },
  microsoft_icon: {
    marginRight: '10px',
    color: '#00A3EE'
  },
  span: {
    fontSize: '14px',
  },
};



const SignIn = ({ login, auth, loginsso }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate('/dashboard');
    }
  }, [localStorage.getItem("token")]);


  const defaultTheme = createTheme();
  const [username, setUsername] = useState('mail@example.com');
  const [password, setPassword] = useState('P@$w0rd`');
  const [userData, setUserData] = useState([])
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(username, password);
  };

  const navigateToForgotPassword = () => {
    navigate('/forgot-password');
  }

  const handleLoginSuccess = (data) => {
    setUserData(data); // Store the response in state, which triggers a re-render
  };

  const handleLogin = async (provider) => {
    const currentUrl = window.location.href; // Get the current URL

    let authUrl = '';
    let clientId = '';
    let redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const responseType = 'code';
    let scope = 'openid profile email';

    switch (provider) {
      case 'google':
        authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        break;
      case 'linkedin':
        authUrl = 'https://www.linkedin.com/oauth/v2/authorization';
        clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
        break;
      case 'microsoft':
        authUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
        clientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID; // Replace with your Microsoft client ID
        break;
      default:
        console.error('Unsupported provider');
        return;
    }

    // Construct the dynamic authorization URL
    const loginUrl = `${authUrl}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
    const loginWindow = window.open(loginUrl, '', 'popup');

    // Check if the login window was successfully opened
    if (!loginWindow) {
      console.error('Failed to open login window. It may have been blocked.');
      return;
    }

    window.addEventListener('message', async function (e) {
      const authorizationCode = e.data;  // Ensure we're extracting the code properly
      const userAction = e.data?.action;
      if (authorizationCode) {
        loginsso(provider, authorizationCode);
        navigate('/dashboard')
        if (loginWindow) loginWindow.close();
      } else if (userAction === 'cancel') {
        console.log('User canceled the login process.');
        if (loginWindow) loginWindow.close(); // Close the popup window only if it's not null
      } else {
        console.error('No authorization code received');
        if (loginWindow) loginWindow.close(); // Close the popup window only if it's not null
      }
    }, { once: true });
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <img src={testImage}
            style={{
              width: '150px',
              height: '50px',
              marginBottom: 15
            }} />

          <Typography component="h6" variant="h5" style={{ color: 'grey', fontSize: '24px' }}>
            Sign in to continue
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoComplete="email"
              onChange={handleUsernameChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              to="/signIn"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
            {/* <Grid container>
            
            <Box
              style={styles.button}
              onClick={()=>handleLogin('google')}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                e.currentTarget.style.boxShadow = styles.buttonHover.boxShadow;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              <GoogleIcon style={styles.icon} />
              <span style={styles.span}>Login with Google</span>
            </Box>
            <Box
              style={styles.button}
              onClick={()=>handleLogin('google')}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                e.currentTarget.style.boxShadow = styles.buttonHover.boxShadow;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              <MicrosoftIcon style={styles.icon} />
              <span style={styles.span}>Login with Google</span>
            </Box>

            </Grid> */}
            <Grid container>
              <Box
                style={styles.button}
                onClick={() => handleLogin('google')}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                  e.currentTarget.style.boxShadow = styles.buttonHover.boxShadow;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                  e.currentTarget.style.boxShadow = 'none';
                }}
                sx={{ mr: 2.5 }} // Add right margin to create space
              >
                <GoogleIcon style={styles.googleicon} />
                <span style={styles.span}>Login with Google</span>
              </Box>
              <Box
                style={styles.button}
                onClick={() => handleLogin('microsoft')}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
                  e.currentTarget.style.boxShadow = styles.buttonHover.boxShadow;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
                  e.currentTarget.style.boxShadow = 'none';
                }}
              >
                <MicrosoftIcon style={styles.microsoft_icon} />
                <span style={styles.span}>Login with Microsoft</span> {/* Changed this to 'Microsoft' */}
              </Box>
            </Grid>


            <Grid container>
              <Grid item xs>
                <Link onClick={() => navigateToForgotPassword()} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  usersDataList: state.users,
  auth: state.auth
});

const mapDispatchToProps = {
  login,
  loginsso
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

