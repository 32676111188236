import { GET_TEST_JOB, GET_SUITECASE_JOB } from "./types";
import testJobService from "../../services/testJob.service";
import { toast } from 'react-toastify';

export const getTestJobs = (proj_id) => async(dispatch) => {
  try {
    let res = await testJobService.getTestJobList(proj_id);
     if(res.status === 200){
      dispatch({
        type : GET_TEST_JOB,
        payload : res.data
      });
    } else {
      toast.error("Unable to fetch test jobs returned status: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getSuiteTestJobs = (id) => async(dispatch) => {
  try {
    let res = await testJobService.getSuiteTestCase(id);
     if(res.status === 200){
      dispatch({
        type : GET_SUITECASE_JOB,
        payload : res.data
      });
    } else {
      toast.error("Unable to fetch suite test job: " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const getTestDetailedReport = (obj) => async(dispatch) => {
  try {
    let res = await testJobService.getTestDetailedReport(obj);
     if(res.status === 200){
       return res.data
    } else {
      toast.error("Unable to fetch suite report : " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};


