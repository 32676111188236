import { RUN_JOB_DATA } from "./types";
import runJobService from "../../services/runJob.service";

export const runJob = (obj) => async (dispatch) => {
  try {
    let res = await runJobService.getJobRunningStatus(obj);
    if (res.status === 200) {
      return res.data;
;
    }
  } catch (error) {
    
  }
};