import { CREATE_JIRA_TICKET,GET_JIRA_LIST } from "../actions/types";
    
      const initialState = {
        JiraList: null,
      };
      
      const jira = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          case GET_JIRA_LIST:
            return {
              ...state,
              JiraList: payload,
              
          };          
            case CREATE_JIRA_TICKET:
              return {
                ...state,
                isjiraCreated: true,
            };

          default:
            return state;
        }
      };
      export default jira;
      