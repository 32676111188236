import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class assignTestCaseService {
    constructor() {
        this.headers = createHeaders();
     }

    async assignTestCaseToUser(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_testcase`;
            const response = await axios.post(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }
 
    async updateTestCaseAssignmentToUsers(obj) { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_testcase`;
            const response = await axios.put(url,obj,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getTestListUserWise() { 
        try {    
            const url = `${ENV_VAR.API_HOST}/api/v1/users_testcase`;
            const response = await axios.get(url,this.headers);
            return response;
        } 
        catch (error) {
            throw error.response.data.error;
        }
    }

}

export default new assignTestCaseService();