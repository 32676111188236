import { GET_FILES_LIST , REPLACE_FILE , UPLOAD_FILE , DELETE_FILE,CHECK_DUPLICATE} from "../actions/types";
    
      const initialState = {
        filesList: null,
      };
      
      const files = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case GET_FILES_LIST:
              return {
                ...state,
                filesList: payload,
            };
        
            case UPLOAD_FILE:
                return {
                  ...state,
                  isFilesUploaded: payload,
              };

              case REPLACE_FILE:
                return {
                  ...state,
                  isFilesReplaced: payload,
              };

              case DELETE_FILE:
                return {
                  ...state,
                  isFilesDeleted: payload,
              };

              case CHECK_DUPLICATE:
                return {
                  ...state,
                  filesList: payload,
              };
          default:
            return state;
        }
      };
      export default files;
      